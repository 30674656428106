import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { demeterPageElementsApi } from '../../../Apis/Apis';
import { DemeterPageElementModel } from '../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import IconButton from '../../Components/Form/Buttons/IconButton';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import { ITreeNode } from '../../Components/TreeView/TreeItem';
import TreeView from '../../Components/TreeView/TreeView';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './PresentationTemplatesPage.module.scss';

interface IPresentationTemplateContentSelectorProps {
    open: boolean;
    pageElements: DemeterPageElementModel[];
    onAdd: (selected: DemeterPageElementModel[]) => void;
    onClose: () => void;
}

const PresentationTemplateContentSelector: React.FC<IPresentationTemplateContentSelectorProps> = (props) => {
    const [translations] = useLanguage();
    const [selectedPageType, setSelectedPageType] = useState<ITreeNode>();
    const [selectedCommodity, setSelectedCommodity] = useState<ITreeNode>();
    const [selectedRegion, setSelectedRegion] = useState<ITreeNode>();
    const [selectedPageElementType, setSelectedPageElementType] = useState<ITreeNode>();
    const [selectedNode, setSelectedNode] = useState<ITreeNode>();
    const [selectedNodes, setSelectedNodes] = useState<ITreeNode[]>([]);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [breadcrumb, setBreadcrumb] = useState<string>('');
    const [cachedRegions, setCachedRegions] = useState<ITreeNode[]>([]);

    const [, getImage, imageResponse, imageError] = useApiWithoutAutoExecute(() => {
        if (!selectedNode || selectedNode.children.length !== 0) {
            return null;
        }
        return demeterPageElementsApi.getDemeterPageElementImage(selectedNode.id);
    });

    const pageTypeNodes: ITreeNode[] = useMemo(() => {
        const unique = Array.from(new Set(props.pageElements.map((p) => p.pageType).filter(Boolean)));
        return unique.map((pt, index) => ({
            id: pt!,
            name: pt!,
            value: pt!,
            parentId: '',
            parentValue: '',
            column: 'left',
            selectedProduct: selectedPageType?.id === pt,
            expanded: false,
            order: index,
            displayOrder: index,
            children: [],
        }));
    }, [props.pageElements, selectedPageType]);

    const commodityNodes: ITreeNode[] = useMemo(() => {
        if (!selectedPageType) {
            return [];
        }
        const filtered = props.pageElements.filter((p) => p.pageType === selectedPageType.id && p.commodity);
        const unique = Array.from(new Set(filtered.map((p) => p.commodity)));
        return unique.map((c, index) => ({
            id: c!,
            name: c && c in translations.commodity ? translations.commodity[c as keyof typeof translations.commodity] : c ?? '',
            value: c!,
            parentId: '',
            parentValue: '',
            column: 'left',
            selectedProduct: selectedCommodity?.id === c,
            expanded: false,
            order: index,
            displayOrder: index,
            children: [],
        }));
    }, [props.pageElements, selectedPageType, selectedCommodity]);

    const regionAndPageElementNodes: ITreeNode[] = useMemo(() => {
        if (!selectedPageType || !selectedCommodity) {
            return [];
        }

        const filtered = props.pageElements.filter((p) => p.pageType === selectedPageType.id && p.commodity === selectedCommodity.id);
        const map = new Map<string, ITreeNode>();

        filtered.forEach((p, index) => {
            const regionId = p.region || 'no-region';
            const subRegionId = p.subRegion || null;

            if (!map.has(regionId)) {
                map.set(regionId, {
                    id: regionId,
                    name: p.region && p.region in translations.region ? translations.region[p.region as keyof typeof translations.region] : p.region ?? '',
                    value: regionId,
                    parentId: '',
                    parentValue: '',
                    column: 'left',
                    selectedProduct: selectedRegion?.id === regionId,
                    expanded: false,
                    order: 0,
                    displayOrder: 0,
                    children: [],
                });
            }

            const regionNode = map.get(regionId)!;
            let parent = regionNode;

            if (subRegionId) {
                let sub = regionNode.children.find((x) => x.id === `${regionId}-${subRegionId}`);
                if (!sub) {
                    sub = {
                        id: `${regionId}-${subRegionId}`,
                        name:
                            p.subRegion && p.subRegion in translations.region
                                ? translations.region[p.subRegion as keyof typeof translations.region]
                                : p.subRegion ?? '',
                        value: subRegionId,
                        parentId: regionId,
                        parentValue: regionId,
                        column: 'left',
                        selectedProduct: false,
                        expanded: false,
                        order: 0,
                        displayOrder: 0,
                        children: [],
                    };
                    regionNode.children.push(sub);
                }
                parent = sub;
            }

            parent.children.push({
                id: p.demeterPageElementGuid,
                name: p.pageElementType || p.demeterPageElementGuid,
                value: p.demeterPageElementGuid,
                parentId: parent.id,
                parentValue: parent.id,
                column: 'left',
                selectedProduct: selectedPageElementType?.id === p.demeterPageElementGuid,
                expanded: false,
                order: p.order ?? index,
                displayOrder: p.order ?? index,
                children: [],
            });
        });

        const regions = Array.from(map.values());
        setCachedRegions(regions);
        return regions;
    }, [props.pageElements, selectedPageType, selectedCommodity, selectedRegion, selectedPageElementType]);

    const updateBreadcrumb = (pageType?: ITreeNode, commodity?: ITreeNode, region?: ITreeNode, pageElement?: ITreeNode) => {
        const parts = [pageType?.name, commodity?.name, region?.name, pageElement?.name].filter(Boolean);
        setBreadcrumb(parts.join(' / '));
    };

    const findParentRegion = (node: ITreeNode | undefined): ITreeNode | undefined => {
        if (!node) {
            return undefined;
        }
        let parentRegion: ITreeNode | undefined;
        cachedRegions.some((region) => {
            if (region.id === node.parentId) {
                parentRegion = region;
                return true;
            }
            return region.children.some((sub) => {
                if (sub.id === node.parentId || sub.children.some((child) => child.id === node.id)) {
                    parentRegion = region;
                    return true;
                }
                return false;
            });
        });
        return parentRegion;
    };

    const handlePageTypeSelect = (event: React.MouseEvent<HTMLElement>) => {
        const { id } = event.currentTarget;
        const node = pageTypeNodes.find((n) => n.id === id);
        if (node) {
            setSelectedPageType(node);
            setSelectedCommodity(undefined);
            setSelectedRegion(undefined);
            setSelectedPageElementType(undefined);
            updateBreadcrumb(node);
        }
    };

    const handleCommoditySelect = (event: React.MouseEvent<HTMLElement>) => {
        const { id } = event.currentTarget;
        const node = commodityNodes.find((n) => n.id === id);
        if (node) {
            setSelectedCommodity(node);
            setSelectedRegion(undefined);
            setSelectedPageElementType(undefined);
            updateBreadcrumb(selectedPageType, node);
        }
    };

    const handleRegionAndPageElementSelect = (event: React.MouseEvent<HTMLElement>) => {
        const { id } = event.currentTarget;
        const allNodes = [...regionAndPageElementNodes.flatMap((r) => [r, ...r.children, ...r.children.flatMap((c) => c.children)])];
        const node = allNodes.find((n) => n.id === id);
        if (!node) {
            return;
        }

        if (node.children.length > 0 && node.parentId === '') {
            setSelectedRegion(node);
            setSelectedPageElementType(undefined);
            updateBreadcrumb(selectedPageType, selectedCommodity, node);
            return;
        }

        if (node.children.length === 0 && node.parentId !== '') {
            const parentRegion = findParentRegion(node);
            setSelectedRegion(parentRegion);
            setSelectedPageElementType(node);
            const exists = selectedNodes.find((n) => n.id === node.id);
            setSelectedNodes((prev) => (exists ? prev.filter((n) => n.id !== node.id) : [...prev, node]));
            updateBreadcrumb(selectedPageType, selectedCommodity, parentRegion, node);
        }

        handleSelect(event);
    };

    const handleSelect = (event: React.MouseEvent<HTMLElement>) => {
        const { id } = event.currentTarget;
        const updated = selectedNodes.map((n) => ({
            ...n,
            selectedProduct: n.id === id,
        }));
        const node = updated.find((n) => n.id === id);
        if (!node) {
            return;
        }
        setSelectedNodes(updated);
        setSelectedNode(node);
        getImage();
        const parentRegion = findParentRegion(node);
        updateBreadcrumb(selectedPageType, selectedCommodity, parentRegion, node);
    };

    useEffect(() => {
        if (imageResponse) {
            const blob = new Blob([imageResponse], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);
            setImageSrc(url);
            return () => URL.revokeObjectURL(url);
        }
        return () => {};
    }, [imageResponse]);

    useEffect(() => {
        if (imageError) {
            setImageSrc(null);
        }
    }, [imageError]);

    const isAddDisabled = selectedNodes.length === 0;

    const handleAdd = () => {
        const selected = selectedNodes.map((x) => props.pageElements.find((p) => p.demeterPageElementGuid === x.id)!);
        props.onAdd(selected);
        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} sx={{ '& .MuiDialog-paper': { maxWidth: '1200px' } }}>
            <DialogTitle>
                <div className={styles.presentation_templates_content_selector_header}>
                    <ComponentHeader title="Choose" />
                    <IconButton handleClick={props.onClose} color="default" size="small">
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={styles.presentation_templates_content_selector_breadcrumb}>{breadcrumb}</div>
                <div className={styles.presentation_templates_content_selector_panes}>
                    <div className={styles.presentation_templates_content_selector_pane}>
                        <TreeView data={pageTypeNodes} searchTerm="" handleSelect={handlePageTypeSelect} disabledNodes={[]} />
                    </div>
                    <div className={styles.presentation_templates_content_selector_pane}>
                        <TreeView data={commodityNodes} searchTerm="" handleSelect={handleCommoditySelect} disabledNodes={[]} />
                    </div>
                    <div className={styles.presentation_templates_content_selector_pane}>
                        <TreeView data={regionAndPageElementNodes} searchTerm="" handleSelect={handleRegionAndPageElementSelect} disabledNodes={[]} />
                    </div>
                    <div className={styles.presentation_templates_content_selector_pane_long}>
                        <div className={styles.presentation_templates_content_selector_selected_nodes}>
                            <TreeView data={selectedNodes} searchTerm="" handleSelect={handleSelect} disabledNodes={[]} />
                        </div>
                        <div className={styles.presentation_templates_content_selector_selected_image}>{imageSrc && <img src={imageSrc} alt="" />}</div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className={styles.presentation_templates_content_selector_actions}>
                    <LinkButton title={translations.actions.cancel} type={LinkButtonType.White} onClick={props.onClose} />
                    <LinkButton title={translations.actions.add} type={LinkButtonType.Blue} onClick={handleAdd} disabled={isAddDisabled} />
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default PresentationTemplateContentSelector;
