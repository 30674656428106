import { useMemo } from 'react';
import { DemeterMarket, DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter/api';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../Redux/Slices/UserSlice';
import useTableDefinitionsByCommodityApi from '../../Apis/Hooks/useTableDefinitionsCommodityApiHook';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';

const useMarketByCommodity = (tableDefinitionType: DemeterTableDefinitionType): DemeterMarket => {
    const market = useApplicationSelector(selectUserCurrentMarket);
    const [searchParameters] = useSearchParameters();
    const tableDefinitionsCommodity = useTableDefinitionsByCommodityApi(tableDefinitionType, DemeterMarket.All);

    return useMemo(() => {
        if (!searchParameters || !tableDefinitionsCommodity) {
            return market;
        }

        const commodityMarket = tableDefinitionsCommodity.find((x) => x.commodity === searchParameters.commodity)?.market;

        return commodityMarket ?? market;
    }, [searchParameters, tableDefinitionsCommodity, market]);
};

export default useMarketByCommodity;
