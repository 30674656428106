import { DemeterPermissionModel, DemeterPermissionType, DemeterRegion, DemeterUserType } from '../../../Generated/Raven-Demeter';
import { setUserPermissions } from '../../../Redux/Slices/UserSlice';
import applicationInitializationService from '../ApplicationInitializationService';
import formattingService from '../Formatting/FormattingService';

class PermissionsService {
    private permissions: DemeterPermissionModel[] = [];

    private userType?: DemeterUserType;

    setPermissions = (storeId: string, permissions: DemeterPermissionModel[], userType?: DemeterUserType): void => {
        this.permissions = permissions;
        this.userType = userType;

        applicationInitializationService.getStore(storeId).dispatch(setUserPermissions({ userPermissions: permissions }));
    };

    getPermissions = (): DemeterPermissionModel[] => this.permissions;

    getPermission = (permissionType: DemeterPermissionType, subPermission?: string): boolean => {
        if (this.userType === DemeterUserType.Administrator) {
            return true;
        }

        // Special rules for PremiumFeatures permission.
        if (permissionType === DemeterPermissionType.PremiumFeatures) {
            if (this.userType !== DemeterUserType.Premium || !subPermission) {
                return true;
            }

            const permission = this.permissions.find((x) => x.permissionType === permissionType);
            if (!permission || permission.subPermissions[formattingService.toCamelCase(DemeterRegion.All)]) {
                return true;
            }

            const subPermissionKey = formattingService.toCamelCase(subPermission);
            return Object.keys(permission.subPermissions)
                .filter((x) => permission.subPermissions[x])
                .some((x) => x.startsWith(subPermissionKey));
        }

        if (subPermission !== undefined && subPermission !== null) {
            const subPermissionKey = formattingService.toCamelCase(subPermission);

            return this.permissions.some(
                (x) => x.permissionType === permissionType && x.hasPermission && x.subPermissions && x.subPermissions[subPermissionKey] === true,
            );
        }

        return this.permissions.some((x) => x.permissionType === permissionType && x.hasPermission);
    };
}

const permissionsService = new PermissionsService();

export default permissionsService;
