import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import IconButton from '../../Form/Buttons/IconButton';
import styles from './SortableList.module.scss';

interface ISortableListProps<T> {
    items: T[] | undefined;
    height?: number;
    emptyContentText?: string;
    allowDelete?: boolean;
    renderItem: (item: T) => React.ReactNode;
    onChange?: (items: T[] | undefined) => void;
    onSelect?: (item: T) => void;
}

interface MappedItem<T> {
    id: number;
    value: T;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SortableList = <T,>(props: ISortableListProps<T>) => {
    const [mappedItems, setMappedItems] = useState<MappedItem<T>[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const nextIdReference = useRef(1);
    const previousItemsReference = useRef<T[] | undefined>(undefined);
    const isLocalUpdate = useRef(false);

    useEffect(() => {
        if (isLocalUpdate.current) {
            isLocalUpdate.current = false;
            return;
        }

        if (props.items === previousItemsReference.current) {
            return;
        }

        const items = props.items ?? [];
        const newMapped: MappedItem<T>[] = items.map((item) => {
            nextIdReference.current += 1;
            return {
                id: nextIdReference.current,
                value: item,
            };
        });

        setMappedItems(newMapped);
        setSelectedIndex(null);
        previousItemsReference.current = props.items;
    }, [props.items]);

    const moveItem = (direction: number) => {
        if (selectedIndex === null) {
            return;
        }

        const newIndex = selectedIndex + direction;
        if (newIndex < 0 || newIndex >= mappedItems.length) {
            return;
        }

        const newMapped = [...mappedItems];
        [newMapped[selectedIndex], newMapped[newIndex]] = [newMapped[newIndex], newMapped[selectedIndex]];

        isLocalUpdate.current = true;
        setMappedItems(newMapped);
        setSelectedIndex(newIndex);
        props.onChange?.(newMapped.map((m) => m.value));
    };

    const handleDelete = () => {
        if (selectedIndex === null) {
            return;
        }

        const newMapped = mappedItems.filter((_, index) => index !== selectedIndex);

        isLocalUpdate.current = true;
        setMappedItems(newMapped);
        setSelectedIndex(null);
        props.onChange?.(newMapped.map((m) => m.value));
    };

    const isMoveUpDisabled = selectedIndex === null || selectedIndex <= 0;
    const isMoveDownDisabled = selectedIndex === null || selectedIndex >= mappedItems.length - 1;
    const isDeleteDisabled = selectedIndex === null;
    const shouldShowEmptyState = mappedItems.length === 0 && !!props.emptyContentText;

    return (
        <div className={styles.sortable_list}>
            <div className={styles.sortable_list_items} style={props.height ? { height: `${props.height}px` } : {}}>
                {mappedItems.map(({ id, value }, index) => {
                    const isSelected = selectedIndex === index;
                    const className = isSelected ? styles.sortable_list_item_selected : styles.sortable_list_item;

                    return (
                        <div
                            key={id}
                            className={className}
                            onClick={() => {
                                props.onSelect?.(value);
                                setSelectedIndex(index);
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {}}
                        >
                            {props.renderItem(value)}
                        </div>
                    );
                })}

                {shouldShowEmptyState && <div className={styles.sortable_list_items_empty}>{props.emptyContentText}</div>}
            </div>

            <div className={styles.sortable_list_actions}>
                <IconButton handleClick={() => moveItem(-1)} color="default" className={styles.sortable_list_action_button} disabled={isMoveUpDisabled}>
                    <ExpandLess className={styles.sortable_list_action_icon} />
                </IconButton>

                <IconButton handleClick={() => moveItem(1)} color="default" className={styles.sortable_list_action_button} disabled={isMoveDownDisabled}>
                    <ExpandMore className={styles.sortable_list_action_icon} />
                </IconButton>

                {props.allowDelete && (
                    <IconButton handleClick={handleDelete} color="default" className={styles.sortable_list_action_button} disabled={isDeleteDisabled}>
                        <Delete className={styles.sortable_list_action_icon} />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

export default SortableList;
