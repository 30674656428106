import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import {
    DemeterCalculationEngineLineModel,
    ListCalculationEngineInputsByGroupResponse,
    RunCalculationEngineResponse,
} from '../../../../Generated/Raven-Demeter';
import IconButton from '../../../Components/Form/Buttons/IconButton';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import RegexValidators from '../../../Core/Validation/RegexValidators';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ValorizationCalculator.module.scss';
import {
    BaseValorizationUpsertRequest,
    CostOfProductionCalculationValues,
    ValorizationModalTab,
    ValorizationRowModel,
    ValorizationStreamRunRequest,
    ValorizationUpsertRequest,
} from './ValorizationCalculatorDefinitions';
import ValorizationDataModal from './ValorizationModals/ValorizationDataModal/ValorizationDataModal';

interface IValorizationRowProps {
    listCalculationEngineInputsByGroupResponse: ListCalculationEngineInputsByGroupResponse;
    runCalculationEngineResponse: RunCalculationEngineResponse;
    calculationEngineLineModel: DemeterCalculationEngineLineModel;
    valorizationUpsertRequest: ValorizationUpsertRequest;
    valorizationStreamRunRequest: ValorizationStreamRunRequest;
    baseValorizationUpsertRequest: BaseValorizationUpsertRequest;
    handleUpdateValorizationUpsertRequest: (valorizationUpsertRequest: ValorizationUpsertRequest, isBaseRequest?: boolean) => void;
    handleUpdateValorizationStreamRunRequests: (newValorizationRunRequest: ValorizationStreamRunRequest, isBaseRequest?: boolean) => void;
}

const defaultMinimumDecimalPlaces = 6;

const ValorizationRow: React.FC<IValorizationRowProps> = (props: IValorizationRowProps) => {
    // Application hooks.
    const [, translate] = useLanguage();
    const [searchParameters, setSearchParameters] = useSearchParameters();

    // Request hooks.
    const [costOfProductionCalculationValues, setCostOfProductionCalculationValues] = useState<CostOfProductionCalculationValues>();

    // Display hooks.
    const [valorizationRow, setValorizationRow] = useState<ValorizationRowModel>();
    const [spotPriceTextValue, setSpotPriceTextValue] = useState<string>();
    const [modalValorizationRow, setModalValorizationRow] = useState<ValorizationRowModel>();
    const [showValorizationDataModal, setShowValorizationDataModal] = useState<boolean>(false);

    const basisOverrideVariable = modalValorizationRow?.basis?.variableName!;
    const costOfProductionOverrideVariable = modalValorizationRow?.costOfProduction?.variableName!;
    const yieldOverrideVariable = modalValorizationRow?.yield?.variableName!;
    const spotPriceOverrideVariable = modalValorizationRow?.spotPrice?.variableName!;

    // Cost of production.
    const energyVariable = costOfProductionCalculationValues?.energy?.variableName;
    const laborVariable = costOfProductionCalculationValues?.labor?.variableName;
    const maintenanceVariable = costOfProductionCalculationValues?.maintenance?.variableName;
    const depreciationVariable = costOfProductionCalculationValues?.depreciation?.variableName;
    const otherVariable = costOfProductionCalculationValues?.other?.variableName;

    // Css classes.
    const keysOfValueOverrides = Object.keys(valorizationRow?.valueOverrides ?? {});
    const basisValueClassName = !keysOfValueOverrides?.includes(basisOverrideVariable)
        ? styles.valorization_calculator_stream_basis
        : styles.valorization_calculator_stream_basis_updated;
    const costOfProductionValueClassName = ![
        costOfProductionOverrideVariable,
        energyVariable,
        laborVariable,
        maintenanceVariable,
        depreciationVariable,
        otherVariable,
    ].some((x) => keysOfValueOverrides.includes(x!))
        ? styles.valorization_calculator_stream_cost_of_production
        : styles.valorization_calculator_stream_cost_of_production_updated;
    const yieldValueClassName = !keysOfValueOverrides.includes(yieldOverrideVariable)
        ? styles.valorization_calculator_stream_yield
        : styles.valorization_calculator_stream_yield_updated;

    const subtotalValueClassName = styles.valorization_calculator_stream_subtotal;

    // Values.
    const yieldValue = valorizationRow?.valueOverrides![yieldOverrideVariable]
        ? valorizationRow.valueOverrides[yieldOverrideVariable]
        : formattingService.toNumberStringWithTrailingZeros(valorizationRow?.yield?.value ?? 0, defaultMinimumDecimalPlaces);
    const costOfProductionValue = valorizationRow?.valueOverrides![costOfProductionOverrideVariable]
        ? valorizationRow.valueOverrides[costOfProductionOverrideVariable]
        : valorizationRow?.costOfProduction?.value ?? 0;
    const basisValue = valorizationRow?.valueOverrides![basisOverrideVariable]
        ? valorizationRow.valueOverrides[basisOverrideVariable]
        : valorizationRow?.basis?.value ?? 0;
    const subtotalValue = formattingService.toNumberStringWithTrailingZeros(valorizationRow?.subtotal?.value ?? 0, defaultMinimumDecimalPlaces);

    const valorizationRowSpotPrice = valorizationRow?.spotPrice?.value;
    const valorizationRowSpotPriceOverrides = valorizationRow?.monthlyValueOverrides?.find((x) => x.valueOverrides[spotPriceOverrideVariable])?.valueOverrides[
        spotPriceOverrideVariable
    ];
    const runStreamRequestSpotPriceOverrides = props.valorizationStreamRunRequest?.monthlyValueOverrides?.find(
        (x) => x.valueOverrides[spotPriceOverrideVariable] === 0 || x.valueOverrides[spotPriceOverrideVariable],
    )?.valueOverrides[spotPriceOverrideVariable];
    const spotPriceUpdated = runStreamRequestSpotPriceOverrides !== valorizationRowSpotPriceOverrides;

    // Set the spot price value the first time we have a valid one, then let the change handler do its thing.
    useEffect(() => {
        if (spotPriceUpdated) {
            return;
        }

        setSpotPriceTextValue(valorizationRowSpotPrice ? `${valorizationRowSpotPrice}` : '');
    }, [valorizationRowSpotPrice]);

    useEffect(() => {
        if (!props.calculationEngineLineModel || !props.runCalculationEngineResponse) {
            return;
        }

        const { groupDisplayName } = props.calculationEngineLineModel;
        const mostRecentData = props.runCalculationEngineResponse?.results?.findLast((y) => new Date(y.asOfDate).getTime() < new Date().getTime())?.lines;

        const newValorizationRow = {
            groupDisplayName: translate(groupDisplayName),
            spotPrice: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Spot Price'),
            basis: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName).find((y) => y.displayName === 'Basis'),
            costOfProduction: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Cost of Production'),
            yield: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName).find((y) => y.displayName === 'Yield'),
            subtotal: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Yield Price'),
            currency: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Price')?.currency,
            unitOfMeasure: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Price')?.unitOfMeasure,
            valueOverrides: props.valorizationUpsertRequest?.valueOverrides ?? {},
            monthlyValueOverrides: props.valorizationUpsertRequest?.monthlyValueOverrides ?? [],
        };

        setCostOfProductionCalculationValues({
            ...costOfProductionCalculationValues,
            energy: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Other Energy')!,
            labor: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Labor')!,
            maintenance: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Maintenance')!,
            depreciation: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Depreciation')!,
            other: mostRecentData?.filter((y) => y.groupDisplayName === groupDisplayName)?.find((y) => y.displayName === 'Other')!,
        });

        setValorizationRow(newValorizationRow as ValorizationRowModel);
        setModalValorizationRow(newValorizationRow as ValorizationRowModel);
    }, [props.listCalculationEngineInputsByGroupResponse, props.calculationEngineLineModel, props.runCalculationEngineResponse]);

    const handleShowValorizationDataModal = (modalTab: ValorizationModalTab) => {
        setSearchParameters({ ...searchParameters, modalTab });
        setShowValorizationDataModal(true);
    };

    const loading = !valorizationRow || !modalValorizationRow || !costOfProductionCalculationValues;

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className={styles.valorization_calculator_stream_row}>
            <div className={styles.valorization_calculator_stream_product}>{valorizationRow.groupDisplayName}</div>
            <div className={spotPriceUpdated ? styles.valorization_calculator_stream_spot_price_updated : styles.valorization_calculator_stream_spot_price}>
                <TextInput
                    skipHandleChangeWhenInvalid
                    value={spotPriceTextValue}
                    handleTextChange={(value) => {
                        setSpotPriceTextValue(value);
                        const numberValue = +value;
                        const { monthlyValueOverrides } = props.valorizationStreamRunRequest;

                        if ((numberValue === valorizationRowSpotPrice && !spotPriceUpdated) || Number.isNaN(numberValue)) {
                            return;
                        }

                        if (monthlyValueOverrides?.length === 0 || !monthlyValueOverrides) {
                            const newRequest = [
                                {
                                    name: props.valorizationStreamRunRequest.displayName,
                                    asOfDate: formattingService.toApiDate(new Date().toISOString()),
                                    valueOverrides: { [spotPriceOverrideVariable]: numberValue },
                                    textOverrides: {}, // Temporary Fix. This field throws an error after API regeneration},
                                },
                            ];

                            props.handleUpdateValorizationStreamRunRequests({ ...props.valorizationStreamRunRequest, monthlyValueOverrides: newRequest });
                        } else if (monthlyValueOverrides) {
                            const newRequest = { ...monthlyValueOverrides[0].valueOverrides, [spotPriceOverrideVariable]: numberValue };

                            props.handleUpdateValorizationStreamRunRequests({
                                ...props.valorizationStreamRunRequest,
                                monthlyValueOverrides: [
                                    {
                                        asOfDate: formattingService.toApiDate(new Date().toISOString()),
                                        valueOverrides: { ...monthlyValueOverrides[0].valueOverrides, ...newRequest },
                                        textOverrides: {}, // Temporary Fix. This field throws an error after API regeneration
                                    },
                                ],
                            });
                        }
                    }}
                    validation={RegexValidators.PositiveOrNegativeNumber}
                />
            </div>
            <div className={basisValueClassName}>
                {basisValue}
                <IconButton handleClick={() => handleShowValorizationDataModal('Basis')}>
                    <EditIcon />
                </IconButton>
            </div>
            <div className={costOfProductionValueClassName}>
                {costOfProductionValue}
                <IconButton handleClick={() => handleShowValorizationDataModal('CostOfProduction')}>
                    <EditIcon />
                </IconButton>
            </div>
            <div className={yieldValueClassName}>
                {yieldValue}
                <IconButton handleClick={() => handleShowValorizationDataModal('Yield')}>
                    <EditIcon />
                </IconButton>
            </div>
            <div className={subtotalValueClassName}>{subtotalValue}</div>

            <ValorizationDataModal
                listCalculationEngineInputsByGroupResponse={props.listCalculationEngineInputsByGroupResponse}
                costOfProductionCalculationValues={costOfProductionCalculationValues}
                setValorizationRow={setValorizationRow}
                valorizationStreamRunRequest={props.valorizationStreamRunRequest}
                valorizationUpsertRequest={props.valorizationUpsertRequest}
                baseValorizationUpsertRequest={props.baseValorizationUpsertRequest}
                handleUpdateValorizationUpsertRequest={props.handleUpdateValorizationUpsertRequest}
                handleUpdateValorizationStreamRunRequests={props.handleUpdateValorizationStreamRunRequests}
                showValorizationDataModal={showValorizationDataModal}
                setShowValorizationDataModal={setShowValorizationDataModal}
                modalValorizationRow={modalValorizationRow}
                setModalValorizationRow={setModalValorizationRow}
            />
        </div>
    );
};

export default ValorizationRow;
