import { FormControlLabel, Radio } from '@mui/material';
import { useEffect, useState } from 'react';
import scssVariables from '../../../../Config.module.scss';
import styles from './Inputs.module.scss';

interface IRadioInputProps {
    title?: string;
    isChecked: boolean;
    disabled?: boolean;
    handleIsChecked?: (isChecked: boolean) => void;
}

const RadioInput = (props: IRadioInputProps): JSX.Element => {
    const [isChecked, setIsChecked] = useState<boolean>(props.isChecked);

    useEffect(() => {
        setIsChecked(props.isChecked);
    }, [props.isChecked]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setIsChecked(checked);
        if (props.handleIsChecked) {
            props.handleIsChecked(checked);
        }
    };

    return (
        <div className={styles.input_input_radio}>
            <FormControlLabel
                sx={{
                    '& .MuiTypography-root': { fontFamily: scssVariables.fontFamily, fontSize: '14px', fontWeight: '400' },
                    '& .MuiRadio-root': { width: '20px', height: '20px', marginRight: '8px' },
                    '& .MuiRadio-root.Mui-checked': { color: scssVariables.stonexPrimaryBlue },
                }}
                control={<Radio checked={isChecked} onChange={handleChange} disabled={props.disabled} color="primary" />}
                label={props.title}
            />
        </div>
    );
};

export default RadioInput;
