import SearchIcon from '@mui/icons-material/Search';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { demeterPresentationTemplatesApi } from '../../../Apis/Apis';
import AgGridBuilder from '../../../Components/AgGridBuilder/AgGridBuilder';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import { ListPresentationTemplatesResponse, PresentationFormat, PresentationTemplateModel } from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import useApi from '../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import TextInput from '../../Components/Form/Inputs/TextInput';
import PageHeader from '../../Components/Headers/PageHeader';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import ActionModal from '../../Components/Modals/ActionModal/ActionModal';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import { ApiListResponse } from '../../Components/Tables/TableWrapper/ApiListResponse';
import TableWrapper from '../../Components/Tables/TableWrapper/TableWrapper';
import useLanguage from '../../Services/Language/useLanguageHook';
import PresentationTemplateActions from './PresentationTemplateActions';
import { columnDefinitions, defaultColumnDefinition, RendererParameters } from './PresentationTemplatesDefinitions';
import styles from './PresentationTemplatesPage.module.scss';

const PresentationTemplatesPage: React.FC = () => {
    const [translations] = useLanguage();
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const [searchTerm, setSearchTerm] = useState<string | undefined>(searchParameters.searchTerm);
    const [skip, setSkip] = useState<number | undefined>(0);
    const [take, setTake] = useState<number | undefined>(applicationConstants.ItemsPerPage);
    const [apiResponse, setApiResponse] = useState<ListPresentationTemplatesResponse>();
    const [showAddModal, setShowAddModal] = useState(false);
    const [name, setName] = useState<string>();
    const navigate = useNavigate();

    const gridReference = useRef<AgGridReact>(null);

    const [loading, listPresentationTemplates, listPresentationTemplatesResponse] = useApi(
        () => demeterPresentationTemplatesApi.listPresentationTemplates(searchTerm, undefined, undefined, undefined, take, skip),
        { errorMessage: translations.presentationTemplates.errors.listPresentationTemplatesError },
    );

    useEffect(() => {
        if (!columnDefinitions) {
            return;
        }
        const actionIndex = columnDefinitions.findIndex((element) => element.field === 'actions');
        columnDefinitions[actionIndex].cellRenderer = (parameters: RendererParameters) => (
            <PresentationTemplateActions presentationTemplate={parameters.data} handleRefresh={listPresentationTemplates} />
        );
    }, [columnDefinitions]);

    useEffect(() => {
        if (!listPresentationTemplatesResponse?.rows) {
            return;
        }

        listPresentationTemplatesResponse.rows = listPresentationTemplatesResponse.rows.map((row: PresentationTemplateModel, index) => ({
            ...row,
            id: index,
        }));

        setApiResponse(listPresentationTemplatesResponse);
    }, [listPresentationTemplatesResponse]);

    const [, addPresentationTemplate, addPresentationTemplateResponse] = useApiWithoutAutoExecute(
        () => {
            if (!isAddFormValid) {
                return null;
            }
            return demeterPresentationTemplatesApi.addPresentationTemplate({ name, format: PresentationFormat.Ppt });
        },
        {
            successMessage: translations.presentationTemplates.success.addPresentationTemplateSuccess,
            errorMessage: translations.presentationTemplates.errors.addPresentationTemplateError,
        },
    );

    const handleTextChange = (value: string) => {
        setSearchTerm(value);
        setSearchParameters({ ...searchParameters, searchTerm: value });
    };

    const handleSearch = () => {
        setSkip(0);
        listPresentationTemplates();
    };

    useEffect(() => {
        setName(undefined);

        if (!addPresentationTemplateResponse) {
            return;
        }
        const editPath = NavigationRoutes.PresentationTemplatesEdit.replace(
            ':presentationTemplateGuid',
            addPresentationTemplateResponse.presentationTemplateGuid!,
        );
        navigate(editPath);
    }, [addPresentationTemplateResponse]);

    const isAddFormValid = !!name;

    return (
        <div className={styles.master_page_container}>
            <PageHeader title={translations.page.presentationTemplates} testId="PresentationTemplatesPageHeader" />
            <div className={styles.presentation_templates_container}>
                <div className={styles.presentation_templates_description}>{translations.presentationTemplates.text.description}</div>
                <div className={styles.presentation_templates_actions_row}>
                    <div className={styles.presentation_templates_actions_search_container}>
                        <SearchIcon className={styles.presentation_templates_actions_search_icon} />
                        <TextInput
                            className={styles.presentation_templates_actions_search_input}
                            placeholder={translations.actions.search}
                            value={searchTerm}
                            handleTextChange={handleTextChange}
                            handleKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                    </div>
                    <LinkButton title={translations.presentationTemplates.actions.add} type={LinkButtonType.White} onClick={() => setShowAddModal(true)} />
                </div>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className={styles.presentation_templates_table}>
                            <TableWrapper
                                apiListResponse={apiResponse}
                                showPagination
                                handlePagination={(apiListResponse: ApiListResponse) => setSkip(apiListResponse.skip ?? 0)}
                                handleTakeCountUpdate={(apiListResponse: ApiListResponse) => setTake(apiListResponse.take ?? applicationConstants.ItemsPerPage)}
                            >
                                <AgGridBuilder
                                    gridRef={gridReference}
                                    rowData={apiResponse?.rows ?? []}
                                    columnDefinitions={columnDefinitions}
                                    defaultColumnDefinition={defaultColumnDefinition}
                                    gridHeightFull
                                    domLayout="autoHeight"
                                    testId="PresentationTemplatesTable"
                                />
                            </TableWrapper>
                        </div>
                        <ActionModal
                            header={translations.presentationTemplates.text.confirmAddHeader}
                            showModal={showAddModal}
                            actionButtonName={translations.actions.add}
                            handleAction={() => {
                                addPresentationTemplate();
                                setShowAddModal(false);
                            }}
                            handleCancel={() => setShowAddModal(false)}
                            actionButtonDisabled={!isAddFormValid}
                        >
                            <>
                                <div>{translations.presentationTemplates.fields.templateName}</div>
                                <TextInput value={name} handleTextChange={setName} required />
                            </>
                        </ActionModal>
                    </>
                )}
            </div>
        </div>
    );
};
export default PresentationTemplatesPage;
