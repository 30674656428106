import { useEffect } from 'react';
import { demeterPresentationTemplatesApi } from '../../../../Apis/Apis';
import { PresentationTemplateModel } from '../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import ActionsCellButton from '../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import useLanguage from '../../../Services/Language/useLanguageHook';

interface IGeneratePresentationTemplateProps {
    presentationTemplate?: PresentationTemplateModel | undefined;
    handleAction: () => void;
}

const GeneratePresentationTemplate: React.FC<IGeneratePresentationTemplateProps> = (props: IGeneratePresentationTemplateProps) => {
    const [translations] = useLanguage();

    const [, generatePresentationTemplate, generatePresentationTemplateResponse] = useApiWithoutAutoExecute(() => {
        if (!props.presentationTemplate) {
            return null;
        }
        return demeterPresentationTemplatesApi.generatePresentationTemplate({
            presentationTemplateGuid: props.presentationTemplate.presentationTemplateGuid,
        });
    });

    useEffect(() => {
        if (generatePresentationTemplateResponse) {
            props.handleAction();
        }
    }, [generatePresentationTemplateResponse]);

    return (
        <ActionsCellButton
            text={translations.presentationTemplates.actions.generate}
            handleClick={generatePresentationTemplate}
            disabled={props.presentationTemplate?.isRunning || false}
        />
    );
};

export default GeneratePresentationTemplate;
