import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterPageElementsApi, demeterPresentationTemplatesApi } from '../../../Apis/Apis';
import { DemeterPageElementModel, PresentationFormat } from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import useApi from '../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import TextInput from '../../Components/Form/Inputs/TextInput';
import ComponentSubHeader from '../../Components/Headers/ComponentSubHeader';
import PageHeaderWithBackNavigation from '../../Components/Headers/PageHeaderWithBackNavigation';
import SortableList from '../../Components/Lists/SortableList/SortableList';
import useLanguage from '../../Services/Language/useLanguageHook';
import PresentationTemplateContentSelector from './PresentationTemplateContentSelector';
import styles from './PresentationTemplatesPage.module.scss';

const PresentationTemplateEditPage: React.FC = () => {
    const [translations] = useLanguage();
    const navigate = useNavigate();
    const { presentationTemplateGuid } = useParams();
    const [openSelector, setOpenSelector] = useState(false);
    const [name, setName] = useState<string>();
    const [format, setFormat] = useState<PresentationFormat>(PresentationFormat.Ppt);
    const [templatePageElements, setTemplatePageElements] = useState<DemeterPageElementModel[] | undefined>();

    const [, , listDemeterPageElementsResponse] = useApi(() => demeterPageElementsApi.listDemeterPageElements());

    const [, , getPresentationTemplateResponse] = useApi(() => demeterPresentationTemplatesApi.getPresentationTemplate(presentationTemplateGuid!), {
        errorMessage: translations.presentationTemplates.errors.getPresentationTemplateError,
    });

    const [, updatePresentationTemplate] = useApiWithoutAutoExecute(
        () => {
            if (!isValid) {
                return null;
            }
            return demeterPresentationTemplatesApi.updatePresentationTemplate({
                presentationTemplateGuid,
                name,
                format,
                rows: templatePageElements,
            });
        },
        {
            successMessage: translations.presentationTemplates.success.updatePresentationTemplateSuccess,
            errorMessage: translations.presentationTemplates.errors.updatePresentationTemplateError,
        },
    );

    useEffect(() => {
        if (!getPresentationTemplateResponse || !getPresentationTemplateResponse.presentationTemplate) {
            return;
        }
        setName(getPresentationTemplateResponse.presentationTemplate.name);
        setFormat(getPresentationTemplateResponse.presentationTemplate.format);
        setTemplatePageElements(getPresentationTemplateResponse.presentationTemplate?.rows);
    }, [getPresentationTemplateResponse]);

    const navigateBack = () => {
        navigate(NavigationRoutes.PresentationTemplates);
    };

    const addTemplatePageElements = (pageElements: DemeterPageElementModel[]) => {
        setTemplatePageElements([...(templatePageElements ?? []), ...pageElements]);
    };

    const isValid = !!presentationTemplateGuid && !!name && !!format;

    const isLoaded = listDemeterPageElementsResponse && getPresentationTemplateResponse;

    const isSaveDisabled = !templatePageElements;

    const isGenerateDisabled = !templatePageElements || templatePageElements.length === 0;

    return (
        <div className={styles.master_page_container}>
            <PageHeaderWithBackNavigation handleBackNavigation={navigateBack} title={translations.presentationTemplates.title.edit} />
            <div className={styles.presentation_templates_actions}>
                <LinkButton
                    title={translations.presentationTemplates.actions.save}
                    type={LinkButtonType.Blue}
                    disabled={isSaveDisabled}
                    onClick={updatePresentationTemplate}
                />
                <LinkButton
                    title={translations.presentationTemplates.actions.chooseContent}
                    type={LinkButtonType.White}
                    onClick={() => setOpenSelector(true)}
                />
                <LinkButton
                    title={translations.presentationTemplates.actions.generate}
                    type={LinkButtonType.White}
                    disabled={isGenerateDisabled}
                    onClick={() => {}}
                />
            </div>
            <div className={styles.presentation_templates_name}>
                <TextInput value={name} handleTextChange={setName} required />
            </div>
            <ComponentSubHeader title={translations.presentationTemplates.text.templateContentHeader} />
            <div className={styles.presentation_templates_content}>
                <div className={styles.presentation_templates_content_order}>
                    {isLoaded && (
                        <SortableList
                            items={templatePageElements}
                            allowDelete
                            height={500}
                            renderItem={(item: DemeterPageElementModel) => (
                                <div>
                                    {item.pageType} / {item.commodity} / {item.region} / {item.pageElementType}
                                </div>
                            )}
                            emptyContentText={translations.presentationTemplates.text.emptyContent}
                            onChange={setTemplatePageElements}
                        />
                    )}
                </div>
            </div>
            <PresentationTemplateContentSelector
                pageElements={listDemeterPageElementsResponse?.rows ?? []}
                open={openSelector}
                onClose={() => setOpenSelector(false)}
                onAdd={addTemplatePageElements}
            />
        </div>
    );
};

export default PresentationTemplateEditPage;
