import React, { useEffect, useMemo, useState } from 'react';
import { demeterUsersApi } from '../../Apis/Apis';
import promiseHandler from '../../Core/Handlers/PromiseHandler';
import applicationConstants from '../../Core/Utility/ApplicationConstants';
import {
    DemeterDashboardPresetChartModel,
    DemeterFeatureType,
    DemeterLeadingIndicatorModel,
    DemeterMarket,
    DemeterPermissionType,
    DemeterTableDefinitionType,
    DemeterUserStoreType,
} from '../../Generated/Raven-Demeter';
import { useApplicationDispatch, useApplicationSelector } from '../../Redux/ReduxStore';
import { MarketPriceModelBySymbol, selectMarketPricesBySymbol } from '../../Redux/Slices/MarketPricesSlice';
import { selectUserCurrentMarket, selectUserMarkets, setUserCurrentMarket } from '../../Redux/Slices/UserSlice';
import useUserStoreApi from '../../Refactor/Apis/Hooks/useUserStoreApiHook';
import Dropdown from '../../Refactor/Components/Form/Inputs/Dropdown';
import PageHeader from '../../Refactor/Components/Headers/PageHeader';
import MenuSettingsModal from '../../Refactor/Components/Modals/ProductsModal/MenuSettingsModal';
import ErrorBoundary from '../../Refactor/Core/ErrorHandling/ErrorBoundary';
import DashboardCommoditySummaryTable from '../../Refactor/Pages/Dashboard/DashboardCommoditySummaryTable';
import DashboardHedgeMonitorProIndicators from '../../Refactor/Pages/Dashboard/DashboardHedgeMonitorProIndicators';
import DashboardMarketIndicators from '../../Refactor/Pages/Dashboard/DashboardMarketIndicators';
import DashboardMarketIntelligenceReports from '../../Refactor/Pages/Dashboard/DashboardMarketIntelligenceReports';
import useFeatureFlag from '../../Refactor/Services/FeatureFlags/useFeatureFlagHook';
import formattingService from '../../Refactor/Services/Formatting/FormattingService';
import { Language, languageOptions } from '../../Refactor/Services/Language/LanguageService';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import { EventActionsEnum, EventCategoriesEnum } from '../../Refactor/Services/Logging/DataLayerDefinitions';
import loggingService from '../../Refactor/Services/Logging/LoggingService';
import usePermission from '../../Refactor/Services/Permissions/usePermissionHook';
import DashboardCharts from './DashboardCharts';
import styles from './DashboardPage.module.scss';
import Indicators, { IndicatorsWidgetInfoInterface } from './Indicators';

const minimumLengthOfMarketOptionsForDropdownDisplay = 2;

const DashboardPage: React.FC = () => {
    // Application hooks.
    const [translations, , language, setLanguage] = useLanguage();
    const dispatch = useApplicationDispatch();
    const markets = useApplicationSelector(selectUserMarkets);
    const market = useApplicationSelector(selectUserCurrentMarket);
    const [indicatorsWidgetInfo, setIndicatorsWidgetInfo] = useState<IndicatorsWidgetInfoInterface[] | undefined>();
    const [isMenuSettingsModalOpen, setIsMenuSettingsModalOpen] = useState(false);
    const [userStoreValue] = useUserStoreApi(DemeterUserStoreType.DisplaySettings);

    // Permissions hooks.
    const isHedgeMonitorProPermissionActive = useFeatureFlag(DemeterFeatureType.HedgeMonitorPro) && usePermission(DemeterPermissionType.HedgeMonitorPro);
    const isMarketIndicatorPermissionActive = useFeatureFlag(DemeterFeatureType.MarketIndicators) && usePermission(DemeterPermissionType.MarketIndicators);

    // Display hooks.
    const [dashboardPresetChart, setDashboardPresetChart] = useState<DemeterDashboardPresetChartModel[]>();
    const pageTitle = useMemo(() => translations.dashboard.title, [language]);
    const isMarketOptionsOn = useFeatureFlag(DemeterFeatureType.Markets);
    const marketPricesBySymbol = useApplicationSelector(selectMarketPricesBySymbol);
    const [indicatorInfo, setIndicatorInfo] = useState<DemeterLeadingIndicatorModel[] | null | undefined>();

    const [userPreferenceIndicatorGuids, setUserPreferenceIndicatorGuids] = useState<string[]>([]);

    const marketOptions = useMemo(() => markets?.map((x) => ({ label: translations.market[x], value: x })), [markets, translations]);

    const updateMarketWithAnalytics = (selectedMarket: DemeterMarket) => {
        loggingService.trackEventWithAnalytics(EventActionsEnum.DropdownSelection, EventCategoriesEnum.UserMarketUpdated, selectedMarket);
        dispatch(setUserCurrentMarket({ userCurrentMarket: selectedMarket }));
    };

    const updateIndicatorsPricesAndAsOfDate = (data: DemeterLeadingIndicatorModel[] | null | undefined, marketPrices?: MarketPriceModelBySymbol) => {
        if (data) {
            const demeterLeadingIndicators = data.map((indicator: DemeterLeadingIndicatorModel) => {
                const marketPrice = indicator.reutersInstrumentCode && marketPrices ? marketPrices[indicator.reutersInstrumentCode ?? ''] : null;
                let value = indicator.currentValue;
                let isFuturesSettlement = false;

                if (marketPrice?.latestPrice && indicator.priceMultiplierFutures) {
                    value = marketPrice.latestPrice * indicator.priceMultiplierFutures;
                } else if (marketPrice?.settlementPrice && indicator.priceMultiplierFutures) {
                    value = marketPrice.settlementPrice * indicator.priceMultiplierFutures;
                    isFuturesSettlement = true;
                }

                let { previousValue } = indicator;

                if (indicator.reutersInstrumentCode) {
                    previousValue = marketPrice?.settlementPrice ? marketPrice.settlementPrice * (indicator.priceMultiplierFutures ?? 1) : undefined;
                }

                return {
                    asOfDate: indicator.asOfDate,
                    latestPriceAsOfDate:
                        indicator.reutersInstrumentCode &&
                        formattingService.toBrowserDateAndTimeFromUTC(marketPricesBySymbol[indicator.reutersInstrumentCode!]?.latestPriceAsOfDate || ''),
                    settlementPriceAsOfDate:
                        indicator.reutersInstrumentCode &&
                        formattingService.toBrowserDateAndTimeFromUTC(marketPricesBySymbol[indicator.reutersInstrumentCode!]?.settlementPriceAsOfDate || ''),
                    title: indicator.displayName,
                    change: marketPrice?.percentChange ? marketPrice.percentChange : indicator.percentChange || 0,
                    currency: indicator.currency as string,
                    reutersInstrumentCode: indicator.reutersInstrumentCode,
                    commodity: indicator.commodity,
                    region: indicator.region,
                    value,
                    isFuturesSettlement,
                    displayDecimalPlacesMinimum: indicator.displayDecimalPlacesMinimum,
                    displayDecimalPlacesMaximum: indicator.displayDecimalPlacesMaximum,
                    indicatorModel: {
                        ...indicator,
                        dataFrequency: indicator.priceFrequency,
                        asOfDate: indicator.asOfDate,
                        previousValue,
                    },
                };
            });

            if (!marketPrices) {
                setUserPreferenceIndicatorGuids(data.map((x) => x.demeterLeadingIndicatorGuid));
            }
            setIndicatorsWidgetInfo(demeterLeadingIndicators);
        }
    };

    const handleLanguageChange = async (newLanguage: Language, isOnLoad: boolean = false) => {
        await setLanguage(newLanguage);
        loggingService.trackEventWithAnalytics(EventActionsEnum.DropdownSelection, EventCategoriesEnum.LanguageChange, newLanguage);
        if (!isOnLoad) {
            await promiseHandler(
                demeterUsersApi.updateUserStore(DemeterUserStoreType.LanguageSelected, {
                    userStoreType: DemeterUserStoreType.LocationData,
                    value: { language: newLanguage as string },
                }),
            );
        }
    };

    const handleIsMenuSettingsModalOpen = () => {
        setIsMenuSettingsModalOpen((previous) => !previous);
    };

    useEffect(() => {
        if (!userStoreValue?.userStore?.value) {
            return;
        }

        setIsMenuSettingsModalOpen(userStoreValue?.userStore?.value[applicationConstants.MenuSettingsUserAlreadyAcknowledged] !== true);
    }, [userStoreValue]);

    return (
        <div className={styles.master_page_container}>
            <div className={styles.indicators_dashboard_section_container}>
                <PageHeader
                    title={pageTitle}
                    rightSideComponent={
                        <div className={styles.indicators_dropdowns_container}>
                            {isMarketOptionsOn && marketOptions?.length >= minimumLengthOfMarketOptionsForDropdownDisplay && (
                                <div className={styles.indicators_dropdowns}>
                                    <Dropdown
                                        value={marketOptions.find((x) => x.value === market)?.value}
                                        options={marketOptions}
                                        handleOptionChange={(selectedMarket) => updateMarketWithAnalytics(selectedMarket)}
                                    />
                                </div>
                            )}

                            <div className={styles.indicators_dropdowns}>
                                <Dropdown
                                    value={languageOptions.find((x) => x.value === language)?.value ?? languageOptions[0].value}
                                    options={languageOptions}
                                    handleOptionChange={handleLanguageChange}
                                />
                            </div>
                        </div>
                    }
                    testId="DashboardPageHeader"
                />
            </div>
            <div className={styles.indicators_dashboard_section_container}>
                <div className={styles.indicators_dashboard_section}>
                    <Indicators
                        market={market}
                        setDashboardPresetChart={setDashboardPresetChart}
                        userPreferenceIndicatorGuids={userPreferenceIndicatorGuids}
                        indicatorsWidgetInfo={indicatorsWidgetInfo}
                        updateIndicatorsPricesAndAsOfDate={updateIndicatorsPricesAndAsOfDate}
                        indicatorInfo={indicatorInfo}
                        setIndicatorInfo={setIndicatorInfo}
                    />
                </div>

                <div className={styles.indicators_dashboard_section_dynamic_container}>
                    {(isMarketIndicatorPermissionActive || isHedgeMonitorProPermissionActive) && (
                        <div className={styles.indicators_dashboard_section_left}>
                            {isHedgeMonitorProPermissionActive ? <DashboardHedgeMonitorProIndicators /> : <DashboardMarketIndicators />}
                        </div>
                    )}
                    {market === DemeterMarket.Energy && (
                        <div className={styles.indicators_dashboard_section_right}>
                            <DashboardMarketIntelligenceReports />
                        </div>
                    )}
                </div>

                {market === DemeterMarket.Dairy && (
                    <div className={styles.indicators_dashboard_section}>
                        <ErrorBoundary>
                            <DashboardCharts dashboardPresetChart={dashboardPresetChart!} />
                        </ErrorBoundary>
                    </div>
                )}

                <div className={styles.indicators_dashboard_section}>
                    <ErrorBoundary>
                        <DashboardCommoditySummaryTable />
                    </ErrorBoundary>
                </div>
            </div>
            <MenuSettingsModal
                open={isMenuSettingsModalOpen}
                tableDefinitionType={DemeterTableDefinitionType.UsersTable}
                handleClose={handleIsMenuSettingsModalOpen}
            />
        </div>
    );
};

export default DashboardPage;
