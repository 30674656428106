import styles from './ActionsCellButton.module.scss';

interface IActionCellButtonInterface {
    text: string;
    disabled?: boolean;
    handleClick: () => void;
}

const ActionsCellButton: React.FC<IActionCellButtonInterface> = (props: IActionCellButtonInterface) => (
    <button type="button" className={styles.actions_cell_button} onClick={props.handleClick} disabled={props.disabled}>
        {props.text}
    </button>
);

export default ActionsCellButton;
