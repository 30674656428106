import { useEffect, useMemo, useState } from 'react';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import {
    DemeterCommodity,
    DemeterMarket,
    DemeterTableDefinitionCommodityModel,
    DemeterTableDefinitionType,
    DemeterUserStoreType,
} from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserCurrentMarket, selectUserMarkets } from '../../../../Redux/Slices/UserSlice';
import useTableDefinitionsByCommodityApi from '../../../Apis/Hooks/useTableDefinitionsCommodityApiHook';
import useUserStoreApi from '../../../Apis/Hooks/useUserStoreApiHook';
import languageService from '../../../Services/Language/LanguageService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import RadioInput from '../../Form/Inputs/RadioInput';
import { MenuTypeForSearchParameter } from '../../MenuFileSelectors/MenuSelectorItem';
import useSearchParameters from '../../Navigation/Hooks/useSearchParametersHook';
import { ITreeNode } from '../../TreeView/TreeItem';
import TreeView from '../../TreeView/TreeView';
import styles from './ProductsModal.module.scss';
import productsModalService from './ProductsModalService';
import ProductsModalWrapper from './ProductsModalWrapper';

interface IMenuSettingsModalProps {
    open: boolean;
    tableDefinitionType: DemeterTableDefinitionType;
    handleClose: () => void;
    setMenuSettings?: (settings: Record<string, string | boolean | DemeterTableDefinitionCommodityModel[]>) => void;
}

const MenuSettingsModal: React.FC<IMenuSettingsModalProps> = (props) => {
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();
    const [userMenuTypePreference, setUserMenuTypePreference] = useState<MenuTypeForSearchParameter>();
    const [hasAcknowledgedModal, setHasAcknowledgedModal] = useState<boolean>(true);
    const [userStoreValue, updateUserStore] = useUserStoreApi(DemeterUserStoreType.DisplaySettings);
    const [selectedCommodities, setSelectedCommodities] = useState<DemeterTableDefinitionCommodityModel[]>([]);
    const tableDefinitionsCommodity = useTableDefinitionsByCommodityApi(DemeterTableDefinitionType.AllFundamentalsAndPrices, DemeterMarket.All);
    const market = useApplicationSelector(selectUserCurrentMarket);
    const userMarkets = useApplicationSelector(selectUserMarkets);

    const treeNodes = useMemo<ITreeNode[]>(() => {
        if (!tableDefinitionsCommodity) {
            return [];
        }

        return tableDefinitionsCommodity
            .filter((x) => x.market && userMarkets.includes(x.market) && x.commodity !== DemeterCommodity.All)
            .sort((a, b) => {
                const marketComparison = (a.market || '').localeCompare(b.market || '');
                if (marketComparison !== 0) {
                    return marketComparison;
                }
                return languageService.translate(a.displayName).localeCompare(languageService.translate(b.displayName));
            })
            .map((commodity) => {
                const commodityName = languageService.translate(commodity.displayName);
                const marketName = languageService.translate(commodity.market || '');
                return {
                    id: `${commodity.topLevelGroup}`,
                    name: commodityName,
                    value: `root_${marketName}_${commodityName}`,
                    parentId: `${commodity.market}`,
                    parentValue: marketName,
                    selectedProduct: selectedCommodities.some((x) => x.topLevelGroup === commodity.topLevelGroup),
                    expanded: false,
                    order: commodity.order,
                    displayOrder: commodity.order,
                    children: [],
                };
            }, []);
    }, [tableDefinitionsCommodity, selectedCommodities]);

    const handleCheckboxSelection = (event: React.MouseEvent<HTMLElement>) => {
        const commoditityMatch = tableDefinitionsCommodity?.find((x) => x.topLevelGroup === event.currentTarget.id);
        if (!selectedCommodities.some((x) => x.topLevelGroup === event.currentTarget.id) && commoditityMatch) {
            setSelectedCommodities((commodities) => [...commodities, commoditityMatch]);
            return;
        }

        setSelectedCommodities((commodities) => [...commodities.filter((x) => x.topLevelGroup !== event.currentTarget.id)]);
    };

    const handleSaveMenuSettings = () => {
        const userPreferences = {
            [applicationConstants.MenuSettingsUserAlreadyAcknowledged]: true,
            [applicationConstants.MenuSettingsTopLevelKey]: userMenuTypePreference ?? searchParameters.topMenuType,
            [applicationConstants.MenuSettingsSelectedCommoditiesKey]: selectedCommodities,
        };
        updateUserStore(userPreferences);
        if (props.setMenuSettings) {
            props.setMenuSettings(userPreferences);
        }

        props.handleClose();
    };

    const handleCloseModal = () => {
        updateUserStore({ ...userStoreValue?.userStore?.value, ...{ [applicationConstants.MenuSettingsUserAlreadyAcknowledged]: true } });
        props.handleClose();
    };

    useEffect(() => {
        if (!userStoreValue?.userStore?.value) {
            return;
        }

        if (!userStoreValue?.userStore?.value[applicationConstants.MenuSettingsUserAlreadyAcknowledged]) {
            setHasAcknowledgedModal(false);
        }

        setUserMenuTypePreference(userStoreValue.userStore.value[applicationConstants.MenuSettingsTopLevelKey]);
    }, [userStoreValue]);

    useEffect(() => {
        if (!userStoreValue?.userStore?.value || !tableDefinitionsCommodity) {
            return;
        }

        if (!userStoreValue?.userStore?.value[applicationConstants.MenuSettingsSelectedCommoditiesKey]) {
            setSelectedCommodities(tableDefinitionsCommodity.filter((x) => x.market === market));
            return;
        }

        setSelectedCommodities(userStoreValue?.userStore?.value[applicationConstants.MenuSettingsSelectedCommoditiesKey]);
    }, [userStoreValue, tableDefinitionsCommodity]);

    return (
        <ProductsModalWrapper
            name="MenuSettingsModal"
            dataTarget={EventDataTargetsEnum.MenuSettingsModal}
            title={hasAcknowledgedModal ? translations.menu.modal.title : translations.menu.modal.welcomeTitle}
            titleClassName={hasAcknowledgedModal ? styles.menu_setting_modal_title : styles.menu_setting_welcome_title}
            setButtonText={hasAcknowledgedModal ? translations.menu.modal.applyButtonText : translations.menu.modal.saveAndGoButtonText}
            cancelButtonText={hasAcknowledgedModal ? translations.menu.modal.cancelButtonText : translations.menu.modal.doThisLater}
            open={props.open}
            isSetButtonDisabled={false}
            handleClose={handleCloseModal}
            handleSave={handleSaveMenuSettings}
        >
            <div className={styles.menu_setting_container}>
                <div className={styles.menu_setting_section}>
                    <div className={styles.menu_setting_section_header}>{translations.menu.modal.commodityPreferencesHeader}</div>
                    <div className={styles.menu_setting_section_text}>{translations.menu.modal.commodityPreferencesText}</div>
                    <div className={styles.menu_setting_tree_container}>
                        <TreeView
                            data={productsModalService.buildTree(treeNodes, 1)?.children}
                            searchTerm=""
                            handleSelect={handleCheckboxSelection}
                            useCheckboxSelection
                        />
                    </div>
                </div>
                <div className={styles.menu_setting_section}>
                    <div className={styles.menu_setting_section_header}>{translations.menu.modal.menuPreferencesHeader}</div>
                    <div className={styles.menu_setting_section_text}>{translations.menu.modal.menuPreferencesText}</div>
                    <div className={styles.menu_setting_radio}>
                        <RadioInput
                            title={translations.menu.modal.productsOverRegions}
                            isChecked={userMenuTypePreference === MenuTypeForSearchParameter.commodity}
                            handleIsChecked={(value) => value && setUserMenuTypePreference(MenuTypeForSearchParameter.commodity)}
                        />
                    </div>
                    <div className={styles.menu_setting_radio}>
                        <RadioInput
                            title={translations.menu.modal.regionsOverProducts}
                            isChecked={userMenuTypePreference === MenuTypeForSearchParameter.region}
                            handleIsChecked={(value) => value && setUserMenuTypePreference(MenuTypeForSearchParameter.region)}
                        />
                    </div>
                </div>
            </div>
        </ProductsModalWrapper>
    );
};

export default MenuSettingsModal;
