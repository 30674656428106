import { ChevronRight, ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import CheckboxInput from '../Form/Inputs/CheckboxInput';
import styles from './TreeView.module.scss';

export interface ITreeNode {
    id: string;
    name: string;
    value: string;
    parentId: string;
    parentValue: string;
    column?: 'left' | 'right';
    selectedProduct: boolean;
    expanded: boolean;
    order: number;
    displayOrder: number;
    children: ITreeNode[];
}

interface ITreeItemProps {
    node: ITreeNode;
    searchTerm: string;
    setSelectedNode: (event: React.MouseEvent<HTMLElement>) => void;
    expanded: boolean;
    disabled: boolean;
    level: number;
    useCheckboxSelection: boolean;
    disabledNodes?: (ITreeNode | null | undefined)[];
    highlightText?: boolean;
}

const TreeItem: React.FC<ITreeItemProps> = (props: ITreeItemProps) => {
    const [expanded, setExpanded] = useState(props.expanded);
    const hasChildren = props.node.children && props.node.children.length > 0;

    useEffect(() => {
        setExpanded(props.expanded);
    }, [props.expanded]);

    const isNodeDisabled = (node: ITreeNode) => {
        if (props.disabledNodes) {
            return props.disabledNodes.some((disabledNode) => disabledNode && disabledNode.id === node.id);
        }
        return false;
    };

    const toggleExpand = () => {
        if (hasChildren && !props.disabled) {
            setExpanded(!expanded);
        }
    };

    const handleSelect = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (!props.disabled) {
            if (hasChildren) {
                toggleExpand();
            } else {
                props.setSelectedNode(event);
            }
        }
    };

    const highlightText = (text: string) => {
        if (!props.searchTerm) {
            return text;
        }

        if (!props.highlightText) {
            return text;
        }

        const parts = text.split(new RegExp(`(${props.searchTerm})`, 'gi'));
        return (
            <>
                {parts.map((part) => {
                    if (part.toLowerCase() === props.searchTerm.toLowerCase()) {
                        return <span className={styles.tree_view_highlight_text}>{part}</span>;
                    }

                    return part;
                })}
            </>
        );
    };

    const itemClass = () => {
        if (props.disabled) {
            return styles.tree_view_item_disabled;
        }

        if (!props.useCheckboxSelection && props.node.selectedProduct) {
            return styles.tree_view_item_selected;
        }
        if (!hasChildren && props.useCheckboxSelection) {
            return styles.tree_view_item_checkbox;
        }

        return styles.tree_view_item;
    };

    return (
        <div>
            <div
                id={`${props.node.id}`}
                onClick={handleSelect}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                className={itemClass()}
                style={props.level ? { paddingLeft: `${props.level * 25 + 15}px` } : { paddingLeft: '15px' }}
            >
                {hasChildren && (
                    <span onClick={toggleExpand} onKeyDown={() => {}} role="button" tabIndex={0}>
                        {expanded ? <ExpandMore className={styles.tree_view_item_icon} /> : <ChevronRight className={styles.tree_view_item_icon} />}
                    </span>
                )}
                {!hasChildren && props.useCheckboxSelection ? (
                    <CheckboxInput title={props.node.name} isChecked={props.node.selectedProduct} disabled={props.disabled} />
                ) : (
                    highlightText(props.node.name)
                )}
            </div>
            {hasChildren && expanded && (
                <div>
                    {props.node.children?.map((childNode) => (
                        <TreeItem
                            key={childNode.id}
                            node={childNode}
                            searchTerm={props.searchTerm}
                            setSelectedNode={props.setSelectedNode}
                            expanded={childNode.expanded || false}
                            disabled={isNodeDisabled(childNode)}
                            disabledNodes={props.disabledNodes}
                            level={props.level + 1}
                            useCheckboxSelection={props.useCheckboxSelection}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TreeItem;
