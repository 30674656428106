import React, { useEffect, useMemo, useState } from 'react';
import CheckboxDropdownInput from '../../../Components/Form/Inputs/CheckboxDropdownInput';
import styles from './Permissions.module.scss';
import { PermissionDefinition, SubPermissionDefinition } from './PermissionsDefinitions';

interface ISubPermissionCheckboxProps {
    permission: PermissionDefinition;
    handleSubPermissionsChange: (subPermissions: SubPermissionDefinition[]) => void;
    disabled?: boolean;
}

const SubPermissionCheckbox: React.FC<ISubPermissionCheckboxProps> = (props: ISubPermissionCheckboxProps) => {
    const [values, setValues] = useState<string[]>([]);

    useEffect(() => {
        setValues(props.permission.subPermissions?.filter((x) => x.hasPermission).map((x) => x.key) ?? []);
    }, [props.permission]);

    const options = useMemo(() => (props.permission.subPermissions ?? []).map((x) => ({ label: x.title, value: x.key })), [props.permission.subPermissions]);

    return (
        <div className={styles.update_permission_input}>
            <CheckboxDropdownInput
                title={props.permission.titleGetter()}
                values={values}
                options={options}
                handleOptionSelect={(newValues: string[]) => {
                    (props.permission.subPermissions ?? []).forEach((x) => {
                        x.hasPermission = newValues.indexOf(x.key) >= 0;
                    });
                    props.handleSubPermissionsChange(props.permission.subPermissions ?? []);
                    setValues(newValues);
                }}
                disabled={props.disabled}
            />
        </div>
    );
};

export default SubPermissionCheckbox;
