import React, { useEffect, useState } from 'react';
import { DemeterRegion } from '../../../../Generated/Raven-Demeter';
import SelectInput, { SelectInputOption } from '../../../Components/Form/Inputs/SelectInput';
import formattingService from '../../../Services/Formatting/FormattingService';
import styles from './Permissions.module.scss';
import { PermissionDefinition } from './PermissionsDefinitions';

interface IPermissionOptionsDropdownProps {
    permission: PermissionDefinition;
    permissionOptions: SelectInputOption<string>[];
    handlePermissionChange: (permissionOption: string) => void;
    disabled?: boolean;
}

const PermissionOptionsDropdown: React.FC<IPermissionOptionsDropdownProps> = (props: IPermissionOptionsDropdownProps) => {
    const getValue = () => {
        const allowedValues = props.permissionOptions.map((x) => x.value);
        return props.permission.subPermissions?.find((x) => allowedValues.find((y) => y === x.key))?.key ?? formattingService.toCamelCase(DemeterRegion.All);
    };

    const [value, setValue] = useState(getValue());

    useEffect(() => {
        setValue(getValue());
    }, [props.permission]);

    return (
        <div className={styles.update_permission_input}>
            <SelectInput
                title={props.permission.titleGetter()}
                options={props.permissionOptions}
                value={value}
                handleOptionChange={(permissionOption: string) => {
                    props.handlePermissionChange(permissionOption);
                    setValue(permissionOption);
                }}
                disabled={props.disabled}
            />
        </div>
    );
};

export default PermissionOptionsDropdown;
