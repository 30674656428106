/* eslint-disable max-len */
import { DemeterCommodity, DemeterPermissionType, DemeterRegion, DemeterTableDefinitionType, ExchangeType } from '../../../../Generated/Raven-Demeter';
import { SelectInputOption } from '../../../Components/Form/Inputs/SelectInput';
import formattingService from '../../../Services/Formatting/FormattingService';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';

export type SubPermissionDefinition = {
    title: string;
    key: string;
    hasPermission: boolean;
};

export type PermissionDefinition = {
    titleGetter: () => string;
    permissionType: DemeterPermissionType;
    isVisible: boolean;
    isDisabled?: boolean;
    requiresAdministratorUser?: boolean;
    requiredAnyPermissionTypeToEdit?: DemeterPermissionType[];
    hasPermission: boolean;
    subPermissions?: SubPermissionDefinition[];
    getPermissionOptions?: () => SelectInputOption<string>[];
    handlePermissionOptionChange?: (permission: PermissionDefinition, permissionOption: string) => void;
};

const handlePremiumFeatureChange = (permission: PermissionDefinition, permissionOption: string) => {
    if (permissionOption === formattingService.toCamelCase(DemeterRegion.UnitedStates)) {
        permission.subPermissions = [
            {
                title: languageService.translate(translationKeys.region.UnitedStates),
                key: formattingService.toCamelCase(DemeterRegion.UnitedStates),
                hasPermission: true,
            },
            {
                title: languageService.translate(translationKeys.exchange.Cme),
                key: `${DemeterTableDefinitionType.CommodityFuturesTable}_${ExchangeType.Cme}`,
                hasPermission: true,
            },
            {
                title: languageService.translate(translationKeys.commodity.FluidMilk),
                key: `${DemeterTableDefinitionType.CommodityPricesTable}_${DemeterRegion.UnitedStates}_${DemeterCommodity.FluidMilk}_`,
                hasPermission: true,
            },
            {
                title: languageService.translate(translationKeys.commodity.Fmmo),
                key: `${DemeterTableDefinitionType.CommodityPricesTable}_${DemeterRegion.UnitedStates}_${DemeterCommodity.Fmmo}_`,
                hasPermission: true,
            },
            {
                title: languageService.translate(translationKeys.commodity.Feed),
                key: `${DemeterTableDefinitionType.CommodityPricesTable}_${DemeterRegion.UnitedStates}_${DemeterCommodity.Feed}_`,
                hasPermission: true,
            },
            {
                title: languageService.translate(translationKeys.commodity.Milk),
                key: `${DemeterTableDefinitionType.CommodityMonthlyProductionTable}_${DemeterRegion.UnitedStates}_${DemeterCommodity.Milk}_`,
                hasPermission: true,
            },
            {
                title: languageService.translate(translationKeys.commodity.HeadlineProduction),
                key: `${DemeterTableDefinitionType.CommodityMonthlyProductionTable}_${DemeterRegion.UnitedStates}_${DemeterCommodity.HeadlineProduction}_`,
                hasPermission: true,
            },
            {
                title: languageService.translate(translationKeys.commodity.Fat),
                key: `${DemeterTableDefinitionType.CommodityMonthlyProductionTable}_${DemeterRegion.UnitedStates}_${DemeterCommodity.Fat}_`,
                hasPermission: true,
            },
            {
                title: languageService.translate(translationKeys.commodity.Protein),
                key: `${DemeterTableDefinitionType.CommodityMonthlyProductionTable}_${DemeterRegion.UnitedStates}_${DemeterCommodity.Protein}_`,
                hasPermission: true,
            },
        ];
    } else {
        permission.subPermissions = [
            {
                title: languageService.translate(translationKeys.region.All),
                key: formattingService.toCamelCase(DemeterRegion.All),
                hasPermission: true,
            },
        ];
    }
};

export const defaultPermissionDefinitions: PermissionDefinition[] = [
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.premiumFeatures),
        permissionType: DemeterPermissionType.PremiumFeatures,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: true,
        subPermissions: [],
        getPermissionOptions: () => [
            { label: languageService.translate(translationKeys.region.All), value: formattingService.toCamelCase(DemeterRegion.All) },
            {
                label: `${languageService.translate(translationKeys.region.UnitedStates)} - ${languageService.translate(translationKeys.words.lite)}`,
                value: formattingService.toCamelCase(DemeterRegion.UnitedStates),
            },
        ],
        handlePermissionOptionChange: handlePremiumFeatureChange,
    },
    {
        titleGetter: () => `${languageService.translate(translationKeys.risk.headers.budget)} ${languageService.translate(translationKeys.page.calculators)}`,
        permissionType: DemeterPermissionType.BudgetCalculator,
        isVisible: true,
        hasPermission: true,
    },
    {
        titleGetter: () =>
            `${languageService.translate(translationKeys.risk.headers.positions)} ${languageService.translate(translationKeys.page.calculators)}`,
        permissionType: DemeterPermissionType.PositionsCalculator,
        isVisible: true,
        hasPermission: true,
    },
    {
        titleGetter: () => `${languageService.translate(translationKeys.risk.headers.margin)} ${languageService.translate(translationKeys.page.calculators)}`,
        permissionType: DemeterPermissionType.FarmerMarginCalculator,
        requiredAnyPermissionTypeToEdit: [DemeterPermissionType.FarmerMarginAdministrator],
        isVisible: true,
        hasPermission: false,
    },

    {
        titleGetter: () => languageService.translate(translationKeys.marketIndicators.title),
        permissionType: DemeterPermissionType.MarketIndicators,
        isVisible: false,
        requiredAnyPermissionTypeToEdit: [DemeterPermissionType.MarketIndicatorAdministrator],
        hasPermission: false,
        subPermissions: [],
    },
    {
        titleGetter: () => languageService.translate(translationKeys.marketIndicators.hedgeMontiorPro),
        permissionType: DemeterPermissionType.HedgeMonitorPro,
        isVisible: false,
        requiredAnyPermissionTypeToEdit: [DemeterPermissionType.HedgeMonitorProAdministrator],
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.users),
        permissionType: DemeterPermissionType.Users,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.usersExport),
        permissionType: DemeterPermissionType.UsersExport,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.hedgeMonitorProAdministrator),
        permissionType: DemeterPermissionType.HedgeMonitorProAdministrator,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.farmerMarginAdministrator),
        permissionType: DemeterPermissionType.FarmerMarginAdministrator,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.valorization),
        permissionType: DemeterPermissionType.Valorization,
        isVisible: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.marketIndicatorAdministrator),
        permissionType: DemeterPermissionType.MarketIndicatorAdministrator,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.marketIndicatorCompliance),
        permissionType: DemeterPermissionType.MarketIndicatorCompliance,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.marketIndicatorDeveloper),
        permissionType: DemeterPermissionType.MarketIndicatorDeveloper,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.marketIndicatorSubjectMatterExpert),
        permissionType: DemeterPermissionType.MarketIndicatorSubjectMatterExpert,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
        subPermissions: [],
    },
];
