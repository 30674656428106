import { useEffect, useState } from 'react';
import { DemeterMarket, DemeterRegion, DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter/api';
import useCommentaryApi from '../../Apis/Hooks/useCommentaryApiHook';
import AggregateRegionSelector from '../../Components/AggregateRegionSelector/AggregateRegionSelector';
import ProjectionChart from '../../Components/Charts/Projection/ProjectionChart';
import SeasonalChart from '../../Components/Charts/Seasonal/SeasonalChart';
import OriginsAndDestinationsContainer from '../../Components/Containers/OriginsAndDestinations/OriginsAndDestinationsContainer';
import UnitOfMeasureMetricImperialDropdown from '../../Components/Form/Inputs/UnitOfMeasureMetricImperialDropdown';
import PageHeaderWithSelections from '../../Components/Headers/PageHeaderWithSelections';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import useTableDefinitionNavigation from '../../Components/Navigation/Hooks/useTableDefinitionNavigationHook';
import SeasonalValueAndPercentTables from '../../Components/Tables/Seasonal/SeasonalValueAndPercentTables';
import useMarketByCommodity from '../../Core/Hooks/useMarketByCommodity';
import useUnitOfMeasure from '../../Core/Hooks/useUnitOfMeasureHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './ExportsPage.module.scss';

const ExportsPage: React.FC = () => {
    // Constants.
    const [translations] = useLanguage();
    const pageTitle = translations.exports.title;
    const tableDefinitionType = DemeterTableDefinitionType.CommodityMonthlyExportsTable;

    // Hooks.
    const [, tableDefinitions, regionCommoditySelection] = useTableDefinitionNavigation(tableDefinitionType);
    const commentary = useCommentaryApi(tableDefinitions?.demeterCommentaryGuid);
    const [unitOfMeasure, setUnitOfMeasure] = useUnitOfMeasure('MetricImperial');
    const marketByCommodity = useMarketByCommodity(tableDefinitionType);
    const [aggregateRegions, setAggregateRegions] = useState<DemeterRegion[]>([]);

    const showAllChartsAndTable = marketByCommodity === DemeterMarket.Dairy;

    useEffect(() => {
        setAggregateRegions([]);
    }, [regionCommoditySelection?.region]);

    return (
        <div className={styles.master_page_container}>
            <PageHeaderWithSelections
                title={pageTitle}
                tableDefinitionType={tableDefinitionType}
                rightSideOfHeaderComponent={
                    <UnitOfMeasureMetricImperialDropdown unitOfMeasure={unitOfMeasure} handleChange={setUnitOfMeasure} testId="ExportsUnitOfMeasureDropdown" />
                }
                testId="ExportsPageHeader"
            />
            {!tableDefinitions || !regionCommoditySelection ? (
                <PageLoadingSpinner />
            ) : (
                <>
                    {showAllChartsAndTable && (
                        <>
                            <div className={styles.exports_main_container}>
                                <div className={styles.exports_projection_chart}>
                                    <ProjectionChart
                                        title={pageTitle}
                                        tableDefinitionType={tableDefinitionType}
                                        regionCommoditySelection={regionCommoditySelection}
                                        aggregateRegions={aggregateRegions}
                                        unitOfMeasure={unitOfMeasure}
                                        testId="ExportsProjectionChart"
                                    />
                                </div>
                                {regionCommoditySelection.region === DemeterRegion.MajorExporters ? (
                                    <div className={styles.exports_commentary_container}>
                                        <AggregateRegionSelector
                                            title={translations.region.MajorExporters}
                                            tableDefinitionType={tableDefinitionType}
                                            regionCommoditySelection={regionCommoditySelection}
                                            handleRegionSelections={setAggregateRegions}
                                        />
                                    </div>
                                ) : (
                                    tableDefinitions?.demeterCommentaryGuid && (
                                        <div className={styles.exports_commentary_container}>
                                            <p>{commentary}</p>
                                        </div>
                                    )
                                )}
                            </div>
                            <OriginsAndDestinationsContainer
                                tableDefinitionType={tableDefinitionType}
                                regionCommoditySelection={regionCommoditySelection}
                                unitOfMeasure={unitOfMeasure}
                                testId="ExportsOriginsAndDestinations"
                            />
                        </>
                    )}
                    <div className={styles.exports_main_container}>
                        <div className={styles.exports_seasonal_chart}>
                            <SeasonalChart
                                title={pageTitle}
                                tableDefinitionType={tableDefinitionType}
                                regionCommoditySelection={regionCommoditySelection}
                                aggregateRegions={aggregateRegions}
                                unitOfMeasure={unitOfMeasure}
                                testId="ExportsSeasonalChart"
                            />
                        </div>
                    </div>
                    {showAllChartsAndTable && (
                        <SeasonalValueAndPercentTables
                            title={pageTitle}
                            tableDefinitionType={tableDefinitionType}
                            regionCommoditySelection={regionCommoditySelection}
                            aggregateRegions={aggregateRegions}
                            unitOfMeasure={unitOfMeasure}
                            testId="ExportsSeasonalValueAndPercentTables"
                        />
                    )}
                </>
            )}
        </div>
    );
};
export default ExportsPage;
