import { PresentationTemplateModel } from '../../../Generated/Raven-Demeter';
import DeletePresentationTemplate from './PresentationTemplateActions/DeletePresentationTemplate';
import DuplicatePresentationTemplate from './PresentationTemplateActions/DuplicatePresentationTemplate';
import EditPresentationTemplate from './PresentationTemplateActions/EditPresentationTemplate';
import GeneratePresentationTemplate from './PresentationTemplateActions/GeneratePresentationTemplate';

interface IPresentationTemplateActionsProps {
    presentationTemplate?: PresentationTemplateModel | undefined;
    handleRefresh: () => void;
}

const PresentationTemplateActions: React.FC<IPresentationTemplateActionsProps> = (props: IPresentationTemplateActionsProps) => (
    <>
        <EditPresentationTemplate presentationTemplate={props.presentationTemplate} />
        <DuplicatePresentationTemplate presentationTemplate={props.presentationTemplate} handleConfirmed={props.handleRefresh} />
        <DeletePresentationTemplate presentationTemplate={props.presentationTemplate} handleConfirmed={props.handleRefresh} />
        <GeneratePresentationTemplate presentationTemplate={props.presentationTemplate} handleAction={props.handleRefresh} />
    </>
);
export default PresentationTemplateActions;
