import { Buffer } from 'buffer';
import { useEffect } from 'react';
import { demeterPresentationTemplatesApi } from '../../../../Apis/Apis';
import { PresentationTemplateModel } from '../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import ActionsCellButton from '../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import browserService from '../../../Services/BrowserService';

interface IDownloadPresentationTemplateProps {
    format: string;
    presentationTemplate?: PresentationTemplateModel | undefined;
}

const DownloadPresentationTemplate: React.FC<IDownloadPresentationTemplateProps> = (props: IDownloadPresentationTemplateProps) => {
    const [, downloadPresentationTemplate, downloadPresentationTemplateResponse] = useApiWithoutAutoExecute(() => {
        if (!props.presentationTemplate) {
            return null;
        }
        return demeterPresentationTemplatesApi.downloadPresentationTemplate(props.presentationTemplate.presentationTemplateGuid);
    });

    useEffect(() => {
        if (!downloadPresentationTemplateResponse?.content || !props.presentationTemplate) {
            return;
        }
        const byteArray = Buffer.from(downloadPresentationTemplateResponse.content, 'base64');
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
        browserService.downloadBlob(blob, `${props.presentationTemplate.name}.pptx`);
    }, [downloadPresentationTemplateResponse]);

    return <ActionsCellButton text={props.format} handleClick={() => downloadPresentationTemplate(true)} />;
};

export default DownloadPresentationTemplate;
