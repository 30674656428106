import React, { useEffect, useMemo } from 'react';
import { demeterCompanyApi, demeterMarketIndicatorsApi, demeterUsersApi } from '../../../../Apis/Apis';
import { DemeterPermissionType, DemeterUserType, MarketIndicatorModel, Permission } from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserPermissions, selectUserType } from '../../../../Redux/Slices/UserSlice';
import useApi from '../../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../../Apis/Hooks/useApiWithoutAutoExecute';
import useCacheOrApi from '../../../Apis/Hooks/useCacheOrApiHook';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { defaultPermissionDefinitions, PermissionDefinition, SubPermissionDefinition } from './PermissionsDefinitions';
import PermissionsRaw from './PermissionsRaw';

export interface IPermissionsProps {
    demeterCompanyGuid?: string;
    demeterUserTrialGuid?: string;
    userType?: DemeterUserType;
}

const Permissions: React.FC<IPermissionsProps> = (props: IPermissionsProps) => {
    const [translations] = useLanguage();
    const currentUserType = useApplicationSelector(selectUserType);
    const myPermissions = useApplicationSelector(selectUserPermissions);

    // Api hooks.
    const [, , listMarketIndicatorsResponse] = useCacheOrApi(
        CacheKeys.ListMarketIndicatorsCurrent,
        () => {
            if (currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.BusinessOwner) {
                return demeterMarketIndicatorsApi.listMarketIndicatorsCurrent();
            }

            return null;
        },
        { stopGlobalErrorHandling: true },
    );

    const [loadingCompanyPermissions, refreshCompanyPermissions, getCompanyPermissionsResponse] = useApi(() => {
        if (!props.demeterCompanyGuid) {
            return null;
        }

        return demeterCompanyApi.getDemeterCompanyPermissions(props.demeterCompanyGuid);
    });

    const [loadingUserPermissions, refreshUserPermissions, getUserPermissionsResponse] = useApi(() => {
        if (!props.demeterUserTrialGuid) {
            return null;
        }

        return demeterUsersApi.getDemeterUserPermissions(props.demeterUserTrialGuid);
    });

    const showLoadingSpinner = loadingCompanyPermissions || loadingUserPermissions;

    useEffect(() => {
        refreshCompanyPermissions();
        refreshUserPermissions();
    }, [props.demeterUserTrialGuid, props.demeterCompanyGuid]);

    const [, updatePermissions] = useApiWithoutAutoExecute(
        (permissions?: Permission[] | undefined) => {
            if (!permissions) {
                return null;
            }

            if (props.demeterUserTrialGuid) {
                return demeterUsersApi.updateDemeterUserPermissions(props.demeterUserTrialGuid, {
                    demeterUserTrialGuid: props.demeterUserTrialGuid,
                    permissions,
                });
            }

            return demeterCompanyApi.updateDemeterCompanyPermissions(props.demeterCompanyGuid!, { demeterCompanyGuid: props.demeterCompanyGuid!, permissions });
        },
        {
            successMessage: translations.permissions.text.permissionsSuccessfullyUpdated,
            errorMessage: translations.permissions.errors.updatePermissionsError,
        },
    );

    // Data hooks.
    const permissionsResponse = useMemo(
        () => (props.demeterUserTrialGuid ? getUserPermissionsResponse?.permissions ?? [] : getCompanyPermissionsResponse?.permissions ?? []),
        [getCompanyPermissionsResponse, getUserPermissionsResponse],
    );

    const getMarketIndicatorSubPermissions = (permissionType: DemeterPermissionType): SubPermissionDefinition[] => {
        const permissionDefinition = defaultPermissionDefinitions.find((x) => x.permissionType === permissionType);
        let subPermissions: SubPermissionDefinition[] = [];

        const getIndicatorKey = (indicator: MarketIndicatorModel) => {
            let key = `${formattingService.toCamelCase(indicator.region)}_${indicator.commodity}`;

            if (indicator.subRegion != null || indicator.dataSource != null) {
                key = `${key}_${indicator.subRegion ?? ''}_${indicator.dataSource ?? ''}`;
            }

            return key;
        };

        // If we are allowed to issues market indicator permissions, we can choose from any indicators.
        if (
            (!props.demeterUserTrialGuid || (currentUserType === DemeterUserType.Administrator && props.userType === DemeterUserType.BusinessOwner)) &&
            (currentUserType === DemeterUserType.Administrator ||
                (currentUserType === DemeterUserType.BusinessOwner &&
                    myPermissions.some(
                        (x) =>
                            permissionDefinition?.requiredAnyPermissionTypeToEdit &&
                            permissionDefinition.requiredAnyPermissionTypeToEdit.some((p) => p === x.permissionType),
                    )))
        ) {
            if (listMarketIndicatorsResponse) {
                subPermissions =
                    listMarketIndicatorsResponse.rows
                        ?.filter((x) => !!x.isPublished)
                        ?.map((indicator) => ({
                            title: indicator.displayName ?? '',
                            key: getIndicatorKey(indicator),
                            hasPermission: false,
                        })) ?? [];
            }
        } else if (props.demeterCompanyGuid) {
            const companySubPermissions =
                getCompanyPermissionsResponse?.permissions?.find((permission) => permission.permissionType === permissionType)?.subPermissions ?? {};

            subPermissions = Object.keys(companySubPermissions)
                .filter((key) => companySubPermissions[key])
                .map((subPermission) => ({
                    title: '',
                    key: subPermission,
                    hasPermission: false,
                }));
        } else if (getUserPermissionsResponse) {
            const userSubPermissions =
                getUserPermissionsResponse.permissions?.find((permission) => permission.permissionType === permissionType)?.subPermissions ?? {};

            subPermissions =
                Object.keys(userSubPermissions).map((subPermission) => ({
                    title: '',
                    key: subPermission,
                    hasPermission: false,
                })) ?? [];
        }

        subPermissions.forEach((subPermission) => {
            if (!subPermission.title) {
                const matchingIndicator = listMarketIndicatorsResponse?.rows?.find((x) => subPermission.key === getIndicatorKey(x));
                if (matchingIndicator?.displayName) {
                    subPermission.title = matchingIndicator.displayName;
                }
            }
        });

        return subPermissions;
    };

    const marketIndicatorSubPermissions = useMemo(
        () => getMarketIndicatorSubPermissions(DemeterPermissionType.MarketIndicators),
        [listMarketIndicatorsResponse, translations, getCompanyPermissionsResponse, getUserPermissionsResponse],
    );

    const marketIndicatorSubjectMatterExpertSubPermissions = useMemo(
        () => getMarketIndicatorSubPermissions(DemeterPermissionType.MarketIndicatorSubjectMatterExpert),
        [listMarketIndicatorsResponse, translations, getCompanyPermissionsResponse, getUserPermissionsResponse],
    );

    // Permissions hooks.
    const permissionDefinitions = useMemo<PermissionDefinition[]>(() => {
        if (props.demeterUserTrialGuid && !getUserPermissionsResponse) {
            return [];
        }

        const newPermissionDefinitions = defaultPermissionDefinitions.map((x) => ({
            ...x,
            subPermissions: x.subPermissions ? x.subPermissions.map((y) => ({ ...y })) : undefined,
        }));

        newPermissionDefinitions.forEach((permissionDefinition) => {
            let matchingPermission = permissionsResponse.find((x) => x.permissionType === permissionDefinition.permissionType);
            const companyMatchingPermission = getCompanyPermissionsResponse?.permissions?.find((x) => x.permissionType === permissionDefinition.permissionType);

            if (!matchingPermission && props.demeterUserTrialGuid && props.demeterCompanyGuid && props.userType === DemeterUserType.Premium) {
                matchingPermission = companyMatchingPermission;
            }

            const addMarketIndicators = () => {
                if (
                    permissionDefinition.permissionType === DemeterPermissionType.MarketIndicators ||
                    permissionDefinition.permissionType === DemeterPermissionType.MarketIndicatorSubjectMatterExpert
                ) {
                    const allSubPermissions =
                        permissionDefinition.permissionType === DemeterPermissionType.MarketIndicatorSubjectMatterExpert
                            ? marketIndicatorSubjectMatterExpertSubPermissions
                            : marketIndicatorSubPermissions;

                    permissionDefinition.subPermissions = allSubPermissions.map((x) => ({ ...x }));

                    permissionDefinition.subPermissions.forEach((subPermission) => {
                        const matchingSubPermission = matchingPermission?.subPermissions && matchingPermission!.subPermissions[subPermission.key];
                        subPermission.hasPermission = !!matchingSubPermission;
                    });

                    if (permissionDefinition.subPermissions.length === 0) {
                        permissionDefinition.isVisible = false;
                    }
                }
            };

            const addPremiumFeatures = () => {
                if (permissionDefinition.permissionType === DemeterPermissionType.PremiumFeatures) {
                    permissionDefinition.subPermissions = Object.keys(matchingPermission?.subPermissions ?? {}).map((key) => ({
                        title: key,
                        key,
                        hasPermission: matchingPermission?.subPermissions[key] ?? false,
                    }));
                }
            };

            if (matchingPermission) {
                permissionDefinition.isVisible = true;
                if (
                    props.demeterUserTrialGuid &&
                    props.demeterCompanyGuid &&
                    (!companyMatchingPermission || !companyMatchingPermission.hasPermission) &&
                    props.userType === DemeterUserType.Premium
                ) {
                    permissionDefinition.isVisible = false;
                }

                permissionDefinition.hasPermission = matchingPermission.hasPermission;
                addMarketIndicators();
                addPremiumFeatures();

                // Disable this permission if the user cannot change it.
                if (
                    currentUserType !== DemeterUserType.Administrator &&
                    ((permissionDefinition.requiredAnyPermissionTypeToEdit &&
                        !myPermissions.some(
                            (x) => permissionDefinition?.requiredAnyPermissionTypeToEdit!.some((p) => p === x.permissionType) && x.hasPermission,
                        )) ||
                        permissionDefinition.requiresAdministratorUser)
                ) {
                    permissionDefinition.isDisabled = true;
                }
            } else if (
                (!props.demeterUserTrialGuid || props.userType === DemeterUserType.BusinessOwner) &&
                (!permissionDefinition.requiredAnyPermissionTypeToEdit ||
                    myPermissions.some(
                        (x) =>
                            permissionDefinition?.requiredAnyPermissionTypeToEdit!.some((permissionType) => permissionType === x.permissionType) &&
                            x.hasPermission,
                    ) ||
                    currentUserType === DemeterUserType.Administrator)
            ) {
                // If we are at the company level and the permission does not exist,
                // I can assign permissions based on my permission level.
                if (!permissionDefinition.requiresAdministratorUser) {
                    permissionDefinition.isVisible = true;
                }
                addMarketIndicators();
            }
        });

        if (currentUserType === DemeterUserType.Administrator && props.userType === DemeterUserType.BusinessOwner) {
            newPermissionDefinitions
                .filter((x) => x.requiresAdministratorUser)
                .forEach((x) => {
                    x.isVisible = true;
                });
        }

        return newPermissionDefinitions.filter((x) => x.isVisible);
    }, [permissionsResponse, marketIndicatorSubPermissions, marketIndicatorSubjectMatterExpertSubPermissions]);

    // Administrators have all permissions, no need to update them.
    // Regular users have no permissions.
    if (props.userType === DemeterUserType.Regular || props.userType === DemeterUserType.Administrator) {
        return null;
    }

    return showLoadingSpinner ? (
        <LoadingSpinner />
    ) : (
        <PermissionsRaw permissionDefinitions={permissionDefinitions} handlePermissionsChange={updatePermissions} />
    );
};

export default Permissions;
