import { ExchangeType } from '../../Generated/Raven-Demeter';

const applicationConstants = {
    ActingDemeterCompanyHeader: 'Company',
    AvailableExchanges: [ExchangeType.Cme, ExchangeType.Eex, ExchangeType.Euronext, ExchangeType.Dce, ExchangeType.Ice, ExchangeType.Sgx, ExchangeType.Otc],
    AvailableEnergyExchanges: [ExchangeType.Cme, ExchangeType.Eex, ExchangeType.Euronext, ExchangeType.Ice],
    // TODO: This will be removed.
    ChartExportOptionsDimensions: {
        MinHeight: 600,
        Height: 800,
        Width: 1200,
        Scale: 2,
        AdjustFactor: 0.67,
        AdjustFactorSeasonal: 0.72,
    },
    HttpStatus: {
        Unauthorized: 401,
        Forbidden: 403,
        Conflict: 409,
    },
    DefaultDisplayDecimalPlacesMaximum: 4,
    DefaultDisplayDecimalPlacesMinimum: 0,
    ItemsPerPage: 20,
    // We only have one article sidebar label in development, therefore, we will use '5996' to test.
    MarketIntelligenceSidebarLabelDevelopment: '5996',
    MaximumTake: 10000,
    MenuSettingsTopLevelKey: 'topMenuType',
    MenuSettingsUserAlreadyAcknowledged: 'userAcknowledged',
    MenuSettingsSelectedCommoditiesKey: 'availableCommodities',
    SessionRefreshTimeInMinutes: 10,
    SessionTokenHeader: 'Session-Token',
    TablePlaceholderZeroOrEmpty: '--',
};

export default applicationConstants;
