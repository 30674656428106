import { ColDef } from 'ag-grid-enterprise';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import { PresentationTemplateModel } from '../../../Generated/Raven-Demeter';
import SmallLoadingSpinner from '../../Components/LoadingSpinner/SmallLoadingSpinner';
import formattingService from '../../Services/Formatting/FormattingService';
import languageService from '../../Services/Language/LanguageService';
import { translationKeys } from '../../Services/Language/TranslationDefinitions';
import DownloadPresentationTemplate from './PresentationTemplateActions/DownloadPresentationTemplate';

// Renderer functions.
export type RendererParameters = {
    data: PresentationTemplateModel;
};

export const columnDefinitions: ColDef[] = [
    {
        field: 'name',
        headerValueGetter: () => languageService.translate(translationKeys.presentationTemplates.fields.templateName),
        minWidth: 100,
    },
    {
        field: 'lastRunAt',
        headerValueGetter: () => languageService.translate(translationKeys.presentationTemplates.fields.lastRunAt),
        cellRenderer: (parameters: RendererParameters) => {
            if (parameters.data.lastRunAt) {
                return formattingService.toLongDateAndTimeFormat(new Date(parameters.data.lastRunAt));
            }
            return applicationConstants.TablePlaceholderZeroOrEmpty;
        },
        minWidth: 100,
    },
    {
        field: 'format',
        headerValueGetter: () => languageService.translate(translationKeys.presentationTemplates.fields.templateFormat),
        cellRenderer: (parameters: RendererParameters) => {
            if (parameters.data.isRunning) {
                return <SmallLoadingSpinner />;
            }
            return (
                <>
                    <DownloadPresentationTemplate format="PPT" presentationTemplate={parameters.data} />
                    <DownloadPresentationTemplate format="PDF" presentationTemplate={parameters.data} />
                </>
            );
        },
        minWidth: 100,
    },
    {
        field: 'actions',
        headerValueGetter: () => languageService.translate(translationKeys.words.actions),
        maxWidth: 300,
        width: 300,
        minWidth: 300,
        pinned: 'right',
        lockPinned: true,
    },
];

export const defaultColumnDefinition = {
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    flex: 1,
    rowDragManaged: true,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: true,
    headerClass: 'ag-header-cell-flex-start',
    sortable: false,
};
