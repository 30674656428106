import React, { useEffect, useRef, useState } from 'react';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import {
    DemeterDataFrequency,
    DemeterFeatureType,
    DemeterMarket,
    DemeterRegion,
    DemeterTableDefinitionCommodityModel,
    DemeterTableDefinitionType,
    DemeterUserStoreType,
} from '../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserMarkets } from '../../../Redux/Slices/UserSlice';
import useTableDefinitionsApi from '../../Apis/Hooks/useTableDefinitionsApiHook';
import useTableDefinitionsByCommodityApi from '../../Apis/Hooks/useTableDefinitionsCommodityApiHook';
import useUserStoreApi from '../../Apis/Hooks/useUserStoreApiHook';
import useFeatureFlag from '../../Services/FeatureFlags/useFeatureFlagHook';
import useSearchParameters from '../Navigation/Hooks/useSearchParametersHook';
import styles from './MenuFileSelectors.module.scss';
import MenuSelectorItem, { IMenuItem, IRegionCommodityValues, MenuTypeForSearchParameter } from './MenuSelectorItem';
import MenuSelectorWithFlipWrapper from './MenuSelectorWithFlipWrapper';
import regionCommodityGroupingService from './RegionCommodityGroupingService';

interface IMenuByRegionCommodityProps {
    tableDefinitionType: DemeterTableDefinitionType;
    showMainRegionsAndCommoditiesGroupsOnly?: boolean;
    rightSideOfBreadcrumbsComponent?: JSX.Element;
}

const MenuByRegionCommodity: React.FC<IMenuByRegionCommodityProps> = (props: IMenuByRegionCommodityProps) => {
    // Application hooks and constants.
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const [userStoreValue, updateUserStore] = useUserStoreApi(DemeterUserStoreType.DisplaySettings);
    const enableUserData = useFeatureFlag(DemeterFeatureType.UserData);
    const userMarkets = useApplicationSelector(selectUserMarkets);

    // Menu Data
    const tableDefinitionsByRegion = useTableDefinitionsApi(props.tableDefinitionType, false, DemeterMarket.All);
    const tableDefinitionsByCommodity = useTableDefinitionsByCommodityApi(props.tableDefinitionType, DemeterMarket.All);
    const [topLevelMenuItems, setTopLevelMenuItems] = useState<IMenuItem[]>([]);
    const [subLevelMenuItems, setSubLevelMenuItems] = useState<IMenuItem[]>([]);
    const [breadCrumbs, setBreadCrumbs] = useState<string>();
    const userStoreReference = useRef<Record<string, string | boolean | DemeterTableDefinitionCommodityModel[]> | null>(null);
    const regionCommoditySelectionReference = useRef<IRegionCommodityValues>({
        region: searchParameters.region ?? null,
        subRegion: searchParameters.subRegion ?? null,
        commodity: searchParameters.commodity ?? null,
        extraParameters: searchParameters.extraParameters ?? '',
        dataFrequency: searchParameters.dataFrequency ?? '',
        subCommodity: null,
        topLevelGroup: null,
        topLevelRegion: null,
        isSubSelection: false,
    });

    const handleMenuAndSearchParametersUpdate = (
        topLevelItems: IMenuItem[],
        subLevelItems: IMenuItem[],
        selection: IRegionCommodityValues,
        topMenuType: MenuTypeForSearchParameter,
    ) => {
        setBreadCrumbs(
            regionCommodityGroupingService.getSelectedBreadCrumbLabel(
                [...topLevelItems, ...subLevelItems],
                topMenuType === MenuTypeForSearchParameter.commodity,
            ),
        );
        setTopLevelMenuItems(topLevelItems);
        setSubLevelMenuItems(subLevelItems);
        regionCommoditySelectionReference.current = { ...selection };

        setSearchParameters({
            region: selection.region ?? searchParameters.region ?? '',
            subRegion: selection.subRegion ?? '',
            commodity: selection.subCommodity ?? selection.commodity ?? searchParameters.commodity,
            extraParameters: selection.extraParameters ?? '',
            dataFrequency: selection.dataFrequency ?? DemeterDataFrequency.Monthly,
            topMenuType,
        });
    };

    const setMenusByRegionOrCommodity = (newSelection: IRegionCommodityValues) => {
        if (!tableDefinitionsByRegion || !tableDefinitionsByRegion?.length || !tableDefinitionsByCommodity || !userStoreReference?.current) {
            return;
        }

        const isCommodity = userStoreReference.current[applicationConstants.MenuSettingsTopLevelKey] === MenuTypeForSearchParameter.commodity;
        const userSelectedCommodities = (
            userStoreReference?.current[applicationConstants.MenuSettingsSelectedCommoditiesKey] as DemeterTableDefinitionCommodityModel[]
        ).filter((x) => x.market && userMarkets.includes(x.market));

        if (!userSelectedCommodities || !userSelectedCommodities.length) {
            return;
        }

        if (!isCommodity || (isCommodity && !tableDefinitionsByCommodity.length)) {
            const availableRegions = regionCommodityGroupingService
                .filterTableDefinitionsByRegionWithUserSelections(tableDefinitionsByRegion, userSelectedCommodities, props.tableDefinitionType)
                .filter((x) => enableUserData || x.region !== DemeterRegion.UserData);
            if (!availableRegions.length) {
                return;
            }

            const selectionUpdate = regionCommodityGroupingService.getMenuSelectionByRegion(availableRegions, newSelection);
            const regionMenuItems = regionCommodityGroupingService.getMenuItemsByRegionsAndCommodities(
                availableRegions,
                selectionUpdate,
                props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
            );

            const selectedRegion = availableRegions.find((x) => x.region === selectionUpdate.topLevelRegion);
            if (!selectedRegion) {
                const regionCommoditiesMenuItems = regionCommodityGroupingService.getMenuItemsByRegionCommodities(
                    availableRegions[0].demeterTableDefinitionGroups,
                    selectionUpdate,
                    props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
                );

                handleMenuAndSearchParametersUpdate(regionMenuItems, regionCommoditiesMenuItems, selectionUpdate, MenuTypeForSearchParameter.region);
                return;
            }

            const selectedSubRegion = selectedRegion.subRegions.find((x) => x === selectionUpdate.subRegion);
            const availableCommodities = selectedRegion.demeterTableDefinitionGroups.filter(
                (x) => !selectedSubRegion || x.subRegions.includes(selectedSubRegion),
            );

            const regionCommoditiesMenuItems = regionCommodityGroupingService.getMenuItemsByRegionCommodities(
                availableCommodities,
                selectionUpdate,
                props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
            );

            handleMenuAndSearchParametersUpdate(regionMenuItems, regionCommoditiesMenuItems, selectionUpdate, MenuTypeForSearchParameter.region);
            return;
        }

        const availableCommodities = regionCommodityGroupingService.filterTableDefinitionsByCommodityWithUserSelections(
            tableDefinitionsByCommodity,
            userSelectedCommodities,
            props.tableDefinitionType,
        );
        if (!availableCommodities.length) {
            return;
        }

        const selectionUpdate = regionCommodityGroupingService.getMenuSelectionByCommodity(availableCommodities, newSelection);
        const commodityMenuItems = regionCommodityGroupingService.getMenuItemsByCommodities(
            availableCommodities,
            selectionUpdate,
            props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
        );
        const selectedCommodity = availableCommodities.find((x) => x.topLevelGroup === selectionUpdate.topLevelGroup);
        if (!selectedCommodity) {
            const commodityRegionsMenuItems = regionCommodityGroupingService.getMenuItemsByRegionsAndCommodities(
                availableCommodities[0].demeterTableDefinitionRegions.filter((x) => enableUserData || x.region !== DemeterRegion.UserData),
                selectionUpdate,
                props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
            );

            handleMenuAndSearchParametersUpdate(commodityMenuItems, commodityRegionsMenuItems, selectionUpdate, MenuTypeForSearchParameter.commodity);
            return;
        }

        const commodityRegionsMenuItems = regionCommodityGroupingService.getMenuItemsByRegionsAndCommodities(
            selectedCommodity?.demeterTableDefinitionSubCommodities
                .find((x) => !!x.commodity && x.commodity === selectionUpdate.subCommodity && x.topLevelGroup === selectionUpdate.topLevelGroup)
                ?.demeterTableDefinitionRegions.filter((x) => enableUserData || x.region !== DemeterRegion.UserData) ??
                selectedCommodity.demeterTableDefinitionRegions.filter((x) => enableUserData || x.region !== DemeterRegion.UserData),
            selectionUpdate,
            props.showMainRegionsAndCommoditiesGroupsOnly ?? false,
        );

        handleMenuAndSearchParametersUpdate(commodityMenuItems, commodityRegionsMenuItems, selectionUpdate, MenuTypeForSearchParameter.commodity);
    };

    const handleMenuFlip = () => {
        const menuSettingsTopLevelType =
            searchParameters.topMenuType === MenuTypeForSearchParameter.region ? MenuTypeForSearchParameter.commodity : MenuTypeForSearchParameter.region;

        const flipRegionCommodityValues: IRegionCommodityValues = {
            region: searchParameters.region ?? '',
            subRegion: searchParameters.subRegion ?? '',
            commodity: searchParameters.commodity ?? '',
            extraParameters: searchParameters.extraParameters ?? '',
            dataFrequency: searchParameters.dataFrequency ?? '',
            subCommodity: regionCommoditySelectionReference.current.subCommodity ?? null,
            topLevelGroup: regionCommoditySelectionReference.current.topLevelGroup ?? null,
            topLevelRegion: regionCommoditySelectionReference.current.topLevelRegion ?? null,
            isSubSelection: !!regionCommoditySelectionReference.current.subCommodity || !!searchParameters.subRegion,
        };

        updateUserStore({ ...userStoreValue?.userStore?.value, ...{ [applicationConstants.MenuSettingsTopLevelKey]: menuSettingsTopLevelType } });
        userStoreReference.current = {
            ...userStoreReference.current,
            [applicationConstants.MenuSettingsTopLevelKey]: menuSettingsTopLevelType,
        };
        setMenusByRegionOrCommodity(flipRegionCommodityValues);
    };

    const handleMenuItemSelection = (selection: IMenuItem) => {
        const selectedSubRegion =
            selection.regionCommodityValues.subRegion !== null
                ? selection.regionCommodityValues.subRegion
                : regionCommoditySelectionReference.current.subRegion;

        const selectedSubCommodity =
            selection.regionCommodityValues.subCommodity !== null
                ? selection.regionCommodityValues.subCommodity
                : regionCommoditySelectionReference.current.subCommodity;

        const selectionRegionCommodityValues: IRegionCommodityValues = {
            region: selection.regionCommodityValues.region,
            subRegion: selectedSubRegion,
            commodity: selection.regionCommodityValues.commodity,
            extraParameters: selection.regionCommodityValues.extraParameters,
            dataFrequency: selection.regionCommodityValues.dataFrequency,
            subCommodity: selectedSubCommodity,
            topLevelGroup: selection.regionCommodityValues.topLevelGroup,
            topLevelRegion: selection.regionCommodityValues.topLevelRegion,
            isSubSelection: selection.regionCommodityValues.isSubSelection,
        };

        setMenusByRegionOrCommodity(selectionRegionCommodityValues);
    };

    useEffect(() => {
        if (!tableDefinitionsByRegion?.length || !tableDefinitionsByCommodity?.length) {
            return;
        }

        const regionCommodityInitialValues: IRegionCommodityValues = {
            region: searchParameters.region ?? null,
            subRegion: null,
            commodity: searchParameters.commodity ?? null,
            extraParameters: searchParameters.extraParameters ?? '',
            dataFrequency: searchParameters.dataFrequency ?? '',
            subCommodity: null,
            topLevelGroup: null,
            topLevelRegion: null,
            isSubSelection: false,
        };
        regionCommoditySelectionReference.current = { ...regionCommodityInitialValues };
        setMenusByRegionOrCommodity(regionCommodityInitialValues);
    }, [tableDefinitionsByRegion, tableDefinitionsByCommodity]);

    const handleMenuItemsUpdate = (settings: Record<string, string | boolean | DemeterTableDefinitionCommodityModel[]>) => {
        userStoreReference.current = { ...settings };
        updateUserStore({ ...settings });
        setMenusByRegionOrCommodity(regionCommoditySelectionReference.current);
    };

    return (
        <MenuSelectorWithFlipWrapper
            rightSideOfBreadcrumbsComponent={props.rightSideOfBreadcrumbsComponent}
            breadCrumbs={breadCrumbs}
            tableDefinitionType={props.tableDefinitionType}
            handleMenuFlip={handleMenuFlip}
            handleMenuUpdate={handleMenuItemsUpdate}
        >
            <div>
                <div className={styles.menu_with_flip_topMenu}>
                    {topLevelMenuItems.map((menuItem: IMenuItem) => (
                        <div key={menuItem.id} className={menuItem.isSelectedItem ? styles.menu_with_flip_topItem_selected : styles.menu_with_flip_topItem}>
                            <MenuSelectorItem
                                menuType="topItem"
                                menuItem={menuItem}
                                classname={menuItem.isSelectedItem ? styles.menu_with_flip_topItem_button_selected : styles.menu_with_flip_topItem_button}
                                buttonClassname={styles.menu_with_flip_topItem_button}
                                labelClassname={styles.menu_with_flip_topItem_button_label}
                                simpleClassname={styles.menu_with_flip_topItem_button_simple}
                                selectorClassname={styles.menu_with_flip_topItem_button_selector}
                                dropdownClassname={styles.menu_with_flip_topItem_button_dropdown}
                                dividerClassName={
                                    menuItem.isSelectedItem ? styles.menu_with_flip_topItem_selected_divider : styles.menu_with_flip_topItem_divider
                                }
                                isSelected={menuItem.isSelectedItem}
                                handleMenuSelection={handleMenuItemSelection}
                                showMainRegionsAndGroupsOnly={props.showMainRegionsAndCommoditiesGroupsOnly}
                            />
                        </div>
                    ))}
                </div>

                <div className={styles.menu_with_flip_subMenu}>
                    {subLevelMenuItems.map((subMenuItem: IMenuItem) => (
                        <div
                            key={subMenuItem.id}
                            className={subMenuItem.isSelectedItem ? styles.menu_with_flip_subItem_selected : styles.menu_with_flip_subItem}
                        >
                            <MenuSelectorItem
                                menuType="subItem"
                                menuItem={subMenuItem}
                                classname={subMenuItem.isSelectedItem ? styles.menu_with_flip_subItem_button_selected : styles.menu_with_flip_subItem_button}
                                buttonClassname={styles.menu_with_flip_subItem_button}
                                labelClassname={styles.menu_with_flip_subItem_button_label}
                                simpleClassname={styles.menu_with_flip_subItem_button_simple}
                                selectorClassname={styles.menu_with_flip_subItem_simple_selector}
                                dropdownClassname={styles.menu_with_flip_subItem_button_dropdown}
                                dividerClassName={
                                    subMenuItem.isSelectedItem ? styles.menu_with_flip_subItem_divider_selected : styles.menu_with_flip_subItem_divider
                                }
                                isSelected={subMenuItem.isSelectedItem}
                                handleMenuSelection={handleMenuItemSelection}
                                showMainRegionsAndGroupsOnly={props.showMainRegionsAndCommoditiesGroupsOnly}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </MenuSelectorWithFlipWrapper>
    );
};

export default MenuByRegionCommodity;
