import ImportExportIcon from '@mui/icons-material/ImportExport';
import SettingsIcon from '@mui/icons-material/Settings';
import React, { Children, useEffect, useState } from 'react';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import { DemeterMarket, DemeterTableDefinitionCommodityModel, DemeterTableDefinitionType, DemeterUserStoreType } from '../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../Redux/Slices/UserSlice';
import useTableDefinitionsByCommodityApi from '../../Apis/Hooks/useTableDefinitionsCommodityApiHook';
import useUserStoreApi from '../../Apis/Hooks/useUserStoreApiHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import IconButton from '../Form/Buttons/IconButton';
import MenuSettingsModal from '../Modals/ProductsModal/MenuSettingsModal';
import useSearchParameters from '../Navigation/Hooks/useSearchParametersHook';
import BreadCrumb from './BreadCrumb';
import styles from './MenuFileSelectors.module.scss';
import { MenuTypeForSearchParameter } from './MenuSelectorItem';

interface MenuSelectorWithFlipWrapperProps {
    breadCrumbs?: string;
    children: JSX.Element;
    rightSideOfBreadcrumbsComponent?: JSX.Element;
    tableDefinitionType: DemeterTableDefinitionType;
    handleMenuFlip: () => void;
    handleMenuUpdate: (settings: Record<string, string | boolean | DemeterTableDefinitionCommodityModel[]>) => void;
}

const MenuSelectorWithFlipWrapper: React.FC<MenuSelectorWithFlipWrapperProps> = (props: MenuSelectorWithFlipWrapperProps) => {
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userStoreValue] = useUserStoreApi(DemeterUserStoreType.DisplaySettings);
    const tableDefinitionsByCommodity = useTableDefinitionsByCommodityApi(DemeterTableDefinitionType.AllFundamentalsAndPrices, DemeterMarket.All);
    const userSelectedMarket = useApplicationSelector(selectUserCurrentMarket);
    const handleFlip = () => {
        props.handleMenuFlip();
    };

    const handleIsModalOpen = () => {
        setIsModalOpen((previous) => !previous);
    };

    const handleMenuSettingsUpdate = (settings: Record<string, string | boolean | DemeterTableDefinitionCommodityModel[]>) => {
        props.handleMenuUpdate(settings);
    };

    useEffect(() => {
        if (!userStoreValue?.userStore?.value || !tableDefinitionsByCommodity) {
            return;
        }

        props.handleMenuUpdate({
            [applicationConstants.MenuSettingsUserAlreadyAcknowledged]: true,
            [applicationConstants.MenuSettingsTopLevelKey]:
                userStoreValue?.userStore?.value[applicationConstants.MenuSettingsTopLevelKey] ??
                searchParameters.topMenuType ??
                MenuTypeForSearchParameter.region,
            [applicationConstants.MenuSettingsSelectedCommoditiesKey]:
                userStoreValue?.userStore?.value[applicationConstants.MenuSettingsSelectedCommoditiesKey] ??
                tableDefinitionsByCommodity.filter((x) => x.market === userSelectedMarket),
        } as Record<string, string | boolean | DemeterTableDefinitionCommodityModel[]>);
    }, [userStoreValue, tableDefinitionsByCommodity]);

    return (
        <>
            <div>
                <span className={styles.menu_flip_wrapper_icons}>
                    <IconButton tooltip={translations.menu.tooltip.flipRegionsAndCommodities} handleClick={handleFlip}>
                        <ImportExportIcon />
                    </IconButton>
                    <IconButton tooltip={translations.menu.tooltip.settings} handleClick={handleIsModalOpen}>
                        <SettingsIcon />
                    </IconButton>
                </span>
                {Children.only(props.children)}
            </div>
            <div className={styles.menu_flip_wrapper_bottom_row}>
                <div className={styles.menu_flip_wrapper_left_side}>
                    <BreadCrumb breadCrumbs={props.breadCrumbs} />
                </div>
                <div className={styles.menu_flip_wrapper_right_side}>
                    {props.rightSideOfBreadcrumbsComponent && <div>{props.rightSideOfBreadcrumbsComponent}</div>}
                </div>
            </div>
            <MenuSettingsModal
                open={isModalOpen}
                tableDefinitionType={props.tableDefinitionType}
                handleClose={handleIsModalOpen}
                setMenuSettings={handleMenuSettingsUpdate}
            />
        </>
    );
};

export default MenuSelectorWithFlipWrapper;
